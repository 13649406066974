import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess, notifyError } from "@/notifications";
import { AxiosResponse } from "axios";

class CustomerApiService extends ModelApiService {
  constructor() {
    super("/customers/", "client");
  }

  sendMessage(id: number, subject: string, message: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/send_email/",
        {subject: subject, message: message},
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Email envoyé");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi de l'email");
          reject(error);
        });
    });
  }

  createEvent(id: number, description: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/events/",
        {
          description: description,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Evènement créé");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }
  
  addComment(id: number, comment: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/comment/",
        {
          comment: comment,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Commentaire ajouté");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  interventionsRecapPdf(id: number, interventionIds: number[]) {
    return api.get(
      `${this.url}${id}/interventions_recap_pdf/?interventions=${interventionIds.join(",")}`,
    );
  }

  swipeContact(srcCustomerId: number, destCustomerId: number, contactId: number) {
    return api.post(
      `${this.url}${srcCustomerId}/swipe_contact/`,
      {
        destination_customer_id: destCustomerId,
        contact_id: contactId,
      },
    );
  }

  prepareAbsorbe(customerId: number, customerToAbsorbeId: number) {
    return api.post(
      `${this.url}${customerId}/absorbe/`,
      {
        customer_to_absorbe_id: customerToAbsorbeId,
        dry_run: true,
      },
    );
  }

  absorbe(customerId: number, customerToAbsorbeId: number) {
    return api.post(
      `${this.url}${customerId}/absorbe/`,
      {
        customer_to_absorbe_id: customerToAbsorbeId,
      },
    );
  }

  toggleSendRdvConfirmationEmail(customerId: number) {
    return new Promise((resolve, reject) => {
      api.post(
        `${this.url}${customerId}/toggle_send_rdv_confirmation_email/`,
      )
        .then((response: AxiosResponse<any>) => {
          const newCustomer = response.data;
          if (newCustomer.send_rdv_confirmation_email) {
            notifySuccess("Email de confirmation de RDV activé");
          } else {
            notifySuccess("Email de confirmation de RDV désactivé");
          }
          resolve(newCustomer);

        }).catch((error: Error) => {
          reject(error);
        });
    });
  }
}

export default CustomerApiService;