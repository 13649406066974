<template>
  <router-link
    class="tool-btn tooltip"
    :to="{ 'name': 'invoice', params: { 'id': modelValue.invoice.id } }">
    <i class="bi bi-file-earmark-ruled" />
    <div class="tooltip-text">
      Voir la facture
    </div>
  </router-link>

  <button
    class="tool-btn tooltip"
    @click.stop="fetchInvoicePDF">
    <Loader v-if="fetchingInvoicePDF" />
    <i v-else class="bi bi-filetype-pdf" />
    <div class="tooltip-text">
      Télécharger facture en PDF
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="sendingInvoiceToCustomerByEmail || (!modelValue.invoice?.contact?.email && !modelValue.invoice?.customer?.contact?.email)"
    @click.stop="sendInvoiceToCustomerByEmail">
    <Loader v-if="sendingInvoiceToCustomerByEmail" />
    <i v-else class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div v-if="modelValue.invoice_sent_date" class="tooltip-text">
        Ré-envoyer la facture au client par email
      </div>
      <div v-else class="tooltip-text">
        Envoyer la facture au client par email
      </div>
      <div v-if="!modelValue.invoice?.contact?.email && !modelValue.invoice?.customer?.contact?.email" class="tooltip-text-red">
        Aucun email n'est associé ni au client, ni à la facture
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="modelValue.address == null || pushingToSendcloud || modelValue.cancelled_locally || modelValue.address.street_number == null"
    @click.stop="pushToSendcloud">
    <Loader v-if="pushingToSendcloud" />
    <SendCloudIcon v-else />
    <div class="tooltip-container">
      <div class="tooltip-text">
        <span v-if="modelValue.sendcloud_shipment_id == null">Envoyer sur Sendcloud</span>
        <span v-else>Ré-envoyer sur Sendcloud</span>
      </div>
      <div v-if="modelValue.address == null" class="tooltip-text-red">
        Aucune adresse de livraison
      </div>
      <div v-else-if="modelValue.address.street_number == null" class="tooltip-text-red">
        L'adresse de livraison n'a pas de numéro de rue mais c'est obligatoire pour Sendcloud
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="$refs.returnModal.show(modelValue)">
    <i class="bi bi-arrow-return-left" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Retour colis
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="cancel">
    <i class="bi bi-x-circle" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Annuler
      </div>
    </div>
  </button>

  <SaleReturnModal ref="returnModal" @update="$emit('update:modelValue', $event)" />
</template>

<script>

import Loader from "@/components/Loader.vue";
import SendCloudIcon from "@/assets/iconsVue/SendCloudIcon.vue";
import { openBlob } from "@/utils";
import { notifyError } from "@/notifications";
import SaleReturnModal from "@/components/modals/SaleReturnModal.vue";

export default {
  name: "SaleToolButtons",
  components: {
    Loader,
    SendCloudIcon,
    SaleReturnModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      pushingToSendcloud: false,
      fetchingInvoicePDF: false,
      sendingInvoiceToCustomerByEmail: false,
      fetchingLabel: false,
    };
  },
  computed: {
    contactFullame() {
      // Créez un tableau pour stocker les parties non nulles de la chaîne
      const nonNullValues = [];

      // Vérifiez chaque variable et ajoutez-la au tableau nonNullValues si elle n'est pas nulle
      if (this.modelValue.contact.company_name) {
        nonNullValues.push(this.modelValue.contact.company_name);
      }
      if (this.modelValue.contact.title) {
        nonNullValues.push(this.modelValue.contact.title);
      }
      if (this.modelValue.contact.first_name) {
        nonNullValues.push(this.modelValue.contact.first_name);
      }
      if (this.modelValue.contact.last_name) {
        nonNullValues.push(this.modelValue.contact.last_name);
      }

      // Concaténez les parties non nulles en utilisant la méthode join() avec un espace comme séparateur
      return nonNullValues.join(" ");
    },
    labelPdfFilename() {
      return `etiquette colis du ${this.$filters.dateStringToHumanizedDate(this.modelValue.created_at)} pour ${this.contactFullame}.pdf`;
    },
  },
  methods: {
    pushToSendcloud() {
      if (this.modelValue.sendcloud_shipment_id != null) {
        const answer = window.confirm("Êtes-vous sûr de vouloir créer à nouveau cette commande sur Sendcloud ?");

        if (!answer) {
          return;
        }
      }

      if (this.modelValue.contact.last_name == null || this.modelValue.contact.last_name.length == 0) {
        notifyError("Nom manquant !");
        return;
      }

      this.pushingToSendcloud = true;

      this.$services.saleV2.pushToSendcloud(this.modelValue.id)
        .then((response) => {
          this.$emit("update:modelValue", {
            ...this.modelValue,
            sendcloud_shipment_id: response.data.sendcloud_shipment_id,
          });
        })
        .finally(() => {
          this.pushingToSendcloud = false;
        });
    },
    async fetchLabel() {
      this.fetchingLabel = true;
      try {
        var labelUrl;
        if (!this.modelValue.my_parcel_shipment_label_url) {
          this.fetchingLabel = true;
          const response = await this.$services.saleV2.fetchLabel(this.modelValue.id);
          labelUrl = response.data;
        } else {
          labelUrl = this.modelValue.my_parcel_shipment_label_url;
        }
        const responsePdf = await fetch(labelUrl);
        const pdfBlob = await responsePdf.blob();
        openBlob(pdfBlob, this.labelFilename, true);

      } finally {
        this.fetchingLabel = false;
      }
    },
    fetchInvoicePDF() {
      this.fetchingInvoicePDF = true;
      this.$services.invoice.fetchPdfAndOpen(this.modelValue.invoice.id)
        .finally(() => {
          this.fetchingInvoicePDF = false;
        });
    },
    sendInvoiceToCustomerByEmail() {
      this.sendingInvoiceToCustomerByEmail = true;

      this.$services.invoice.sendToCustomerEmail(this.modelValue.invoice.id)
        .finally(() => {
          this.sendingInvoiceToCustomerByEmail = false;
        });
    },
    cancel() {
      const answer = window.confirm("Êtes-vous sûr de vouloir annuler cette commande ?");

      if (!answer) {
        return;
      }

      this.$services.saleV2.patch(this.modelValue.id, 
        {
          sendcloud_status: "cancelled",
        },
      )
        .then((response) => {
          this.$emit("update:modelValue", response.data);
        });
    },
  },
};

</script>

<style>

</style>