
import { createWebHistory, createRouter } from "vue-router";

// Layout
import NavigationLayout from "@/views/NavigationLayout.vue";

// Dashboard
import DashboardView from "@/views/DashboardView.vue";

// Staff Calendar
import StaffCalendarView from "@/views/StaffCalendarView.vue";

// My rewards
import RewardsView from "@/views/RewardsView.vue";

// Stats
import StatsView from "@/views/stats/StatsView.vue";

// My provider profile
import MyProviderProfile from "@/views/myProviderProfile/MyProviderProfile.vue";

// Planning
import Planning from "@/views/planning/Planning.vue";

// Jobs
import JobsView from "@/views/JobsView.vue";

// Other routes
import accountingRoutes from "@/router/accounting";
import customerRoutes from "@/router/customer";
import stockRoutes from "@/router/stock";
import interventionRoutes from "@/router/intervention";
import saleRoutes from "@/router/sale";
import funnelRoutes from "@/router/funnel";


const routes = [
  {
    path: "/",
    redirect: "/tableau-de-bord",
  },
  {
    abstract: true,
    path: "/nav",
    component: NavigationLayout,
    children: [

      //// Dashboard

      {
        path: "/tableau-de-bord",
        component: DashboardView,
        name: "dashboard",
      },

      ...customerRoutes,
      ...interventionRoutes,
      ...accountingRoutes,
      ...stockRoutes,
      ...saleRoutes,
      ...funnelRoutes,

      //// Stats
      
      {
        path: "/statistiques",
        component: StatsView,
        name: "stats",
      },

      //// Planning

      {
        path: "/planning",
        component: Planning,
        name: "planning",

      },

      //// Staff Calendar
      
      {
        path: "/calendrier-des-equipes",
        component: StaffCalendarView,
        name: "staff-calendar",
      },

      //// My provider profil
      
      {
        path: "/mon-profil-prestataire",
        component: MyProviderProfile,
        name: "my-provider-profil",
      },

      //// My rewards
      
      {
        path: "/bonus",
        component: RewardsView,
        name: "rewards",
      },

      //// My rewards
      
      {
        path: "/jobs",
        component: JobsView,
        name: "jobs",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
