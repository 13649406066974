import { defineStore } from "pinia";

export interface State {
  technicians: Array<any>;
}

export const useTechniciansStore = defineStore("technicians", {
  state: (): State => ({
    technicians: [],
  }),
  getters: {
    getTechnicians: state => {
      return state.technicians;
    },
  },
  actions: {
    setTechnicians(technicians: Array<any>) {
      this.technicians = technicians;
    },
  },
});
