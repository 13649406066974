import { api, encode } from "@/api";
import { notifySuccess, notifyError } from "@/notifications";

class ModelApiService {
  url: string;
  verboseName: string;
  searchResultsDefaultLimit: number = 8;

  constructor(url: string, verboseName: string) {
    this.url = url;
    this.verboseName = verboseName;
  }

  create(data: object) : Promise<object> {
    return new Promise((resolve, reject) => {
      api.post(this.url, data)
        .then((response) => {
          notifySuccess(`${this.verboseName} créé`);
          resolve(response);
        }).catch((error: Error) => {
          if (!error.response || error.response.status != 400) {
            notifyError(`Erreur lors de la création de ${this.verboseName}`);
          }
          reject(error);
        });
    });
  }

  patch(id: number, data: object) : Promise<object> {
    return new Promise((resolve, reject) => {
      api.patch(this.url + id + "/", data)
        .then((response) => {
          notifySuccess(`${this.verboseName} mis à jour`);
          resolve(response);
        }).catch((error: Error) => {
          if (error.response && error.response.status === 400) {
            notifyError("Des champs n'ont pas été remplis correctements");
          } else if (error.response && error.response.status === 404) {
            notifyError(`Vous essayez de modifier un ${this.verboseName} qui n'existe pas ou plus.`, "Essayez de rafraichir la page");
          } else {
            notifyError(`Impossible de mettre à jour le ${this.verboseName}`);
          }
          reject(error);
        });
    });
  }

  list(args: object | string | null = null, limit: number | null = this.searchResultsDefaultLimit, page: number | null = 1) : Promise<object> {
    // args can even be an object or a string. If args is a string, the requests will end with ?q=args


    const finalArgs = {
      q: null as string | null,
      limit,
      page,
      ...(typeof args === "string" || args instanceof String ? { q: args } : args),
    };

    return api.get(`${this.url}?${encode(finalArgs)}`);

  }

  search(args: object | string | null = null, limit: number | null = this.searchResultsDefaultLimit, page: number | null = 1) : Promise<object> {
    // args can even be an object or a string. If args is a string, the requests will end with ?q=args


    const finalArgs = {
      q: null as string | null,
      limit,
      page,
      ...(typeof args === "string" || args instanceof String ? { q: args } : args),
    };

    return api.get(`${this.url}search/?${encode(finalArgs)}`);

  }

  retrieve(id: number | string) : Promise<object> {
    return api.get(this.url + id + "/");
  }

  schema() : Promise<object> {
    return api.get(`${this.url}schema/`);
  }

  delete(id: number) : Promise<object> {
    return new Promise((resolve, reject) => {
      api.delete(this.url + id + "/")
        .then((response) => {
          notifySuccess(`${this.verboseName} supprimé`);
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

}

export default ModelApiService;