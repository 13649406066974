<!-- TODO this component can be put in common with ObjectCustomerCard and InterventionInvoiceCard and InterventionQuoteCard -->
<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Client</span>
      <button
        v-if="!editing"
        class="small-tool-btn"
        :disabled="!allowEdit"
        @click="startEditing">
        <i class="bi bi-pencil-square" />
      </button>
    </div>

    <div class="card-body">
      <div v-if="editing">
        <div v-if="customer" class="form-group">
          Client actuellement sélectionné : {{ $filters.printCustomer(customer) }}
        </div>

        <div class="form-group">
          <label class="form-label">
            Nouveau client à assigner
          </label>

          <div style="display: flex;">
            <SelectObject
              type="customer"
              placeholder="Sélectionnez un client"
              style="width: 100%; max-width: 400px;"
              @select="onCustomerSelected" />
            <Loader v-if="saving" />
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end;">
          <button
            class="btn-primary"
            :disabled="saving"
            @click="endEditing">
            Annuler
          </button>
        </div>
      </div>

      <div v-else>
        <div v-if="customer">
          <CustomerWidget
            :modelValue="customer"
            showBillingInfo />
        </div>

        <i v-else>Aucun</i>
      </div>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import Loader from "@/components/Loader.vue";
import CustomerWidget from "@/components/ednComponents/CustomerWidget.vue";


export default {
  name: "ObjectCustomerCard",
  components: {
    SelectObject,
    Loader,
    CustomerWidget,
  },
  props: {
    customer: {
      type: Object,
      required: false,
      default: null,
    },
    allowEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    editBtnTooltipText: {
      type: String,
      required: false,
      default: null,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      saving: false,
    };
  },
  methods: {
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { id: this.customer.id }});
    },
    startEditing() {
      this.editing = true;
    },
    endEditing() {
      this.editing = false;
      this.saving = false;
    },
    onCustomerSelected(customer) {
      const answer = window.confirm(`Etes-vous sûr de vouloir assigner le client ${this.$filters.printCustomer(customer)} ?`);
      if (answer) {
        this.onSave(customer)
          .then(() => {
            this.endEditing();
          }).finally(() => {
            this.saving = false;
          });
      }
    },
  },
  
};
</script>

<style>

</style>