<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            placeholder="Numéro, nom, email, téléphone..."
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Montant
          </div>
          <div class="input-group">
            <input
              v-model="amount"
              type="number"
              class="form-control">
            <div class="input-group-append">
              €
            </div>
          </div>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Status du paiement local
          </div>
          <select
            v-model="localPaymentState"
            class="form-select">
            <option value="all">
              Tout
            </option>
            <option value="unpaid">
              Impayé
            </option>
            <option value="partially_paid">
              Partiellement payé
            </option>
            <option value="unpaid_or_partially_paid">
              Impayé ou partiellement payé
            </option>
            <option value="paid">
              Payé complètement
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Status Odoo
          </div>
          <select
            v-model="odooPaymentState"
            class="form-select">
            <option value="all">
              Tout
            </option>
            <option value="not_pushed">
              Pas encore sur Odoo
            </option>
            <option value="unpaid_or_partially_paid">
              Impayé ou partiellement payé
            </option>
            <option value="paid">
              Payé
            </option>
            <option value="error">
              En erreur
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Rappels de paiements
          </div>
          <select
            v-model="paymentRequestState"
            class="form-select">
            <option value="all">
              Tout
            </option>
            <option value="no_payment_request_sent">
              Aucun rappel envoyé
            </option>
            <option value="first_payment_request_sent">
              Premier rappel envoyé
            </option>
            <option value="second_payment_request_sent">
              Second rappel envoyé
            </option>
            <option value="third_payment_request_sent">
              Troisième rappel envoyé
            </option>
            <option value="transfered_to_collection_company">
              Transféré à la société de recouvrement
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Méthode de paiement
          </div>
          <select
            v-model="paymentMethod"
            class="form-select">
            <option :value="null">
              Tout
            </option>
            <option value="wire">
              Virement
            </option>
            <option value="cash">
              Liquide
            </option>
            <option value="bancontact">
              Bancontact
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            A été envoyé au client
          </div>
          <select
            v-model="wasSentToCustomer"
            class="form-select">
            <option :value="null">
              Tout
            </option>
            <option value="true">
              Oui
            </option>
            <option value="false">
              Non
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option :value="null">
              Date de création (Plus réçent d'abord)
            </option>
            <option value="created_at">
              Date de création (Plus ancien d'abord)
            </option>
          </select>
        </div>


        <div class="filter-item">
          <div class="filter-item-title">
            Auteur
          </div>
          <select v-model="authorId" class="form-select">
            <option
              :value="null">
              Tous
            </option>
            <option
              v-for="(technicianChoice, index) in technicians"
              :key="index"
              :value="technicianChoice.id">
              {{ technicianChoice.first_name }} {{ technicianChoice.last_name }}
            </option>
          </select>
        </div>


        <div class="filter-item">
          <ResetFiltersButton :store="invoiceListStore" />
        </div>
      </div>

      <SimpleButton
        tooltipText="Nouvelle facture"
        @click="$router.push({ name: 'funnel.customer', params: { mode: 'invoice'} })">
        <InvoiceIcon status="invoice" />
      </SimpleButton>

      <SimpleButton
        :loading="exportingSelectedInvoicesToPdf"
        disableWhenLoading
        text="Exporter la sélection en PDF"
        icon="bi bi-filetype-pdf"
        @click="exportSelectedInvoicesToPdf" />
    </div>

  

    <div class="cat-list" style="margin: 5px;">
      <router-link
        :to="{ name: 'invoice-list' }"
        class="cat-list-item"
        :class="{ 'active': !$route.query.journalId }">
        Tous
        <span
          v-if="invoiceListStore.getTotal('all') != null">
          ({{ invoiceListStore.getTotal('all') }})
        </span>
      </router-link>
      
      <router-link
        v-for="(journal, index) in journals"
        :key="index"
        :to="{ name: 'invoice-list', query: { journalId: journal.id } }"
        class="cat-list-item"
        :class="{ 'active': $route.query.journalId == journal.id }">
        {{ journal.origin.code }} {{ journal.invoice_code_prefix }}
        <span
          v-if="invoiceListStore.getTotal(journal.id) != null">
          ({{ invoiceListStore.getTotal(journal.id) }})
        </span>
      </router-link>
    </div>

    <router-view style="min-height: 100%;" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import { useInvoiceListStore } from "@/stores/invoiceList";
import { useTechniciansStore } from "@/stores/technicians";
import { useJournalsStore } from "@/stores/journals";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";

export default {
  name: "InvoiceListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    DateRangeInput,
    InvoiceIcon,
    SimpleButton,
  },
  data() {
    return {
      invoiceListStore: useInvoiceListStore(),
      techniciansStore: useTechniciansStore(),
      journalsStore: useJournalsStore(),
      exportingSelectedInvoicesToPdf: false,
    };
  },
  computed: {
    query: {
      get() {
        return this.invoiceListStore.getFilter("q");
      },
      set(val) {
        this.invoiceListStore.addFilter("q", val);
        this.invoiceListStore.refresh();
      },
    },
    createdDateRange: {
      get() {
        return [
          this.invoiceListStore.getFilter("created_at_after"),
          this.invoiceListStore.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.invoiceListStore.addFilter("created_at_after", val[0]);
        this.invoiceListStore.addFilter("created_at_before", val[1]);
        this.invoiceListStore.refresh();
      },
    },
    amount: {
      get() {
        return [
          this.invoiceListStore.getFilter("amount"),
        ];
      },
      set(val) {
        this.invoiceListStore.addFilter("amount", val);
        this.invoiceListStore.refresh();
      },
    },
    localPaymentState: {
      get() {
        const res = this.invoiceListStore.getFilter("local_payment_state");
        if (res == null) {
          return "all";
        } else {
          return res;
        }
      },
      set(val) {
        if (val == "all") {
          val = null;
        }
        this.invoiceListStore.addFilter("local_payment_state", val);
        this.invoiceListStore.refresh();
      },
    },
    odooPaymentState: {
      get() {
        const res = this.invoiceListStore.getFilter("odoo_payment_state");
        if (res == null) {
          return "all";
        } else {
          return res;
        }
      },
      set(val) {
        if (val == "all") {
          val = null;
        }
        this.invoiceListStore.addFilter("odoo_payment_state", val);
        this.invoiceListStore.refresh();
      },
    },
    paymentMethod: {
      get() {
        return this.invoiceListStore.getFilter("payment_method");
      },
      set(val) {
        this.invoiceListStore.addFilter("payment_method", val);
        this.invoiceListStore.refresh();
      },
    },
    wasSentToCustomer: {
      get() {
        return this.invoiceListStore.getFilter("was_sent_to_customer");
      },
      set(val) {
        this.invoiceListStore.addFilter("was_sent_to_customer", val);
        this.invoiceListStore.refresh();
      },
    },
    sortBy: {
      get() {
        return this.invoiceListStore.getFilter("sort_by");
      },
      set(val) {
        this.invoiceListStore.addFilter("sort_by", val);
        this.invoiceListStore.refresh();
      },
    },
    authorId: {
      get() {
        return this.invoiceListStore.getFilter("author_id");
      },
      set(val) {
        this.invoiceListStore.addFilter("author_id", val);
        this.invoiceListStore.refresh();
      },
    },
    paymentRequestState: {
      get() {
        return this.invoiceListStore.getFilter("payment_request_state");
      },
      set(val) {
        this.invoiceListStore.addFilter("payment_request_state", val);
        this.invoiceListStore.refresh();
      },
    },
    journals() {
      return this.journalsStore.getJournals;
    },
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
    
  },
  methods: {
    exportSelectedInvoicesToPdf() {
      this.exportingSelectedInvoicesToPdf = true;

      this.$services.invoice.fetchPdfs(this.invoiceListStore.getFilters)
        .finally(() => {
          this.exportingSelectedInvoicesToPdf = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>
