<template>
  <div>
    <div class="scanner">
      <div v-if="scanning" id="qr-reader" style="width: 600px" />

      <div v-else>
        <button class="btn-primary" @click="resumeScan">
          <i class="bi bi-upc-scan" style="font-size: 5rem;" />
        </button>
      </div>

      <LoaderView v-if="fetchingProduct" />

      <Modal ref="no-product-found-modal" width="small">
        <template #default>
          <strong>Aucun produit correspondant trouvé</strong>
        </template>
        <template #buttons>
          <button
            class="btn-primary"
            @click="$refs['no-product-found-modal'].hide()">
            Ok
          </button>
        </template>
      </Modal>

      <Modal ref="several-products-found-modal" width="small">
        <template #default>
          <strong>Plusieurs produits correspondants trouvés</strong>
        </template>
        <template #buttons>
          <button
            class="btn-primary"
            @click="$refs['several-products-found-modal'].hide()">
            Ok
          </button>
        </template>
      </Modal>
    </div>

    <div class="card" style="margin: 10px;">
      <div class="card-header">
        <span class="title"><i class="bi bi-cart" /> Panier</span>
      </div>

      <div class="card-body">
        <i v-if="cart.length == 0">Panier vide</i>
        <table>
          <tr
            v-for="(line, index) in cart"
            :key="index">
            <td style="padding: 3px; vertical-align: middle;">
              {{ line.product.name }}
            </td>
            <td style="padding: 3px; vertical-align: middle;">
              <div style="display: flex;">
                <input
                  v-model="line.nb_unit"
                  type="number"
                  class="form-control"
                  style="max-width: 60px;"
                  min="1">
                <button class="tool-btn" @click="removeLineFromCart(index)">
                  <i class="bi bi-x-square" />
                </button>
              </div>    
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="card" style="margin: 10px;">
      <div class="card-body">
        <div style="display: flex;">
          <button
            class="btn"
            :class="{ 'btn-primary': action == 'add', 'btn-outline-secondary': action != 'add'}"
            style="margin: 3px;"
            :disabled="cart.length == 0"
            @click="action = 'add'">
            <i class="bi bi-box-arrow-in-down" />
            Ajouter le panier au stock
          </button>

          <button
            class="btn"
            :class="{ 'btn-primary': action == 'move', 'btn-outline-secondary': action != 'move'}"
            style="margin: 3px;"
            :disabled="cart.length == 0"
            @click="action = 'move'">
            <i class="bi bi-box-arrow-right" />
            Déplacer le panier d'un stockage à un autre
          </button>

          <button
            class="btn"
            :class="{ 'btn-primary': action == 'remove', 'btn-outline-secondary': action != 'remove'}"
            style="margin: 3px;"
            :disabled="cart.length == 0"
            @click="action = 'remove'">
            <i class="bi bi-box-arrow-up" />
            Vendre ou retirer le panier du stock
          </button>
        </div>
      </div>
    </div>

    <div v-if="action == 'remove' || action == 'move'" class="card" style="margin: 10px;">
      <div class="card-header">
        <span class="title"><i class="bi bi-boxes" /><i class="bi bi-arrow-bar-right" /> Retirer de l'espace de stockage</span>
      </div>
      <div class="card-body">
        <div>
          <button
            v-for="(storage, index) in storages"
            :key="index"
            class="btn"
            :class="{ 'btn-primary': srcStorage != null && srcStorage.id == storage.id, 'btn-outline-secondary': srcStorage == null || srcStorage.id != storage.id}"
            style="margin: 3px;"
            @click="srcStorage = storage">
            {{ storage.name }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="action == 'add' || action == 'move'" class="card" style="margin: 10px;">
      <div class="card-header">
        <span class="title"><i class="bi bi-arrow-bar-right" /><i class="bi bi-boxes" /> Ajouter à l'espace de stockage</span>
      </div>
      <div class="card-body">
        <div>
          <button
            v-for="(storage, index) in storages"
            :key="index"
            class="btn"
            :class="{ 'btn-primary': dstStorage != null && dstStorage.id == storage.id, 'btn-outline-secondary': dstStorage == null || dstStorage.id != storage.id}"
            style="margin: 3px;"
            @click="dstStorage = storage">
            {{ storage.name }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="action != null" class="card" style="margin: 10px;">
      <div class="card-body">
        <div style="display: flex; justify-content: center;">
          <button
            v-if="action != null"
            :disabled="!canSubmit"
            class="btn-primary"
            style="margin-top: 10px;"
            @click="submit">
            <span v-if="action == 'add'">
              Ajouter à {{ dstStorage?.name }}
            </span>
            <span v-else-if="action == 'remove'">
              Retirer de {{ srcStorage?.name }}
            </span>
            <span v-else-if="action == 'move'">
              Déplacer de {{ srcStorage?.name }} à {{ dstStorage?.name }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LoaderView from "@/components/LoaderView.vue";
import Modal from "@/components/modals/Modal.vue";
import {Html5Qrcode} from "html5-qrcode";
import { useStoragesStore } from "@/stores/storages";

export default {
  name: "ProductScannerView",
  components: {
    LoaderView,
    Modal,
  },
  data() {
    return {
      fetchingProduct: false,
      severalProductsFound: null,
      cart: [
        {
          product: {
            name: "produit test 1",
          },
          nb_unit: 1,
        },
        {
          product: {
            name: "produit test 2",
          },
          nb_unit: 1,
        },
        {
          product: {
            name: "produit test",
          },
          nb_unit: 1,
        },
      ],
      action: null,
      srcStorage: null,
      dstStorage: null,
      scanner: null,
      scanning: false,
      storagesList : useStoragesStore(),
    };
  },
  computed: {
    canSubmit() {
      return this.cart.length > 0 && ((this.action == "add" && this.dstStorage != null) || (this.action =="remove" && this.srcStorage != null) || (this.action == "move" && this.srcStorage != null && this.dstStorage != null));
    },
    storages() {
      return this.storagesList.getResults;
    },
  },
  mounted() {
    const action = this.$route.query.action;
    if (action && ["add", "remove", "move"].includes(action)) {
      this.action = action;
    }

    const srcStorageId = this.$route.query.src_storage;
    if (srcStorageId) {
      this.$services.storage.retrieve(srcStorageId)
        .then((response) => {
          this.srcStorage = response.data;
        });
    }

    const dstStorageId = this.$route.query.dst_storage;
    if (dstStorageId) {
      this.$services.storage.retrieve(dstStorageId)
        .then((response) => {
          this.dstStorage = response.data;
        });
    }

  },
  methods: {
    startScanning() {
      Html5Qrcode.getCameras().then(devices => {
        /**
         * devices would be an array of objects of type:
         * { id: "id", label: "label" }
         */
        if (devices && devices.length) {
          var cameraId = devices[0].id;
          // .. use this to start scanning.

          this.scanner = new Html5Qrcode(/* element id */ "qr-reader");

          this.scanner.start(
            cameraId, 
            {
              fps: 2,    // Optional, frame per seconds for qr code scanning
              qrbox: { width: 250, height: 250 },  // Optional, if you want bounded box UI
            },
            // eslint-disable-next-line no-unused-vars
            (decodedText, decodedResult) => {
              this.pauseScan();
              this.onBarcodeScanned(decodedText);
            },
            // eslint-disable-next-line no-unused-vars
            (errorMessage) => {
              if (!this.scanning) {
                this.pauseScan();
              }
            });
        }
      });
    },
    pauseScan() {
      this.scanning = false;
      this.scanner.pause(true);
    },
    resumeScan() {
      this.scanning = true;

      if (this.scanner) {
        this.scanner.resume();
      } else {
        this.startScanning();
      }
      
    },
    onBarcodeScanned(barcode) {
      this.fetchingProduct = true;

      this.$services.product.list({barcode: barcode})
        .then((response) => {
          if (response.data.results.length == 1) {
            const index = this.cart.findIndex((line) => {
              return line.product.id == response.data.results[0].id;
            });

            if (index != -1) {
              this.cart[index].nb_unit = this.cart[index].nb_unit + 1;
            } else {
              this.cart.push(
                {
                  product: response.data.results[0],
                  nb_unit: 1,
                },
              );
            }
          } else if (response.data.results.length == 0) {
            this.$refs["no-product-found-modal"].show();
          } else {
            this.severalProductsFound = response.data.results;
            this.$refs["several-products-found-modal"].show();
          }
        })
        .finally(() => {
          this.fetchingProduct = false;
        });
    },
    removeLineFromCart(index) {
      this.cart.splice(index, 1);
    },
    async submit() {
      const cartToSubmit = [];

      this.cart.forEach((line) => {
        cartToSubmit.push({
          product_id: line.product.id,
          nb_unit: line.nb_unit,
        });
      });

      if (this.action == "add") {
        await this.$services.storage.addCart(this.dstStorage.id, cartToSubmit);

      } else if (this.action == "move") {
        await this.$services.storage.moveCart(this.srcStorage.id, this.dstStorage.id, cartToSubmit);
          
      } else if (this.action == "remove") {
        await this.$services.storage.removeCart(this.srcStorage.id, cartToSubmit);
      }

      this.refresh();
    },
  },
};

</script>


<style lang="scss" scroped>

  .scanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .html5-qrcode-scanner > .title {
    display: none;
  }

  .html5-qrcode-scanner > .footer {
    display: none;
}

</style>