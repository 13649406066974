<template>
  <ObjectViewLayout
    :title="title"
    :loading="initializing || customer == null || newIntervention == null || invoice == null"
    withBackButton
    hideHeaderOnMobile
    @back="$router.push({ name: 'invoice', params: { 'id': $route.params.id } })">
    <template #default>
      <div class="card">
        <div class="card-body">
          <CreateInterventionForm
            v-model="newIntervention"
            :customer="customer" />
        </div>

        <div class="card-footer justify-right">
          <button
            class="btn btn-primary"
            :disabled="!canSubmit || submitting"
            :class="{ 'loading': submitting }"
            @click="submit">
            Créer l'intervention
          </button>
        </div>
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import CreateInterventionForm from "@/components/forms/CreateInterventionForm.vue";

export default {
  name: "AddNewInterventionToInvoiceView",
  components: {
    ObjectViewLayout,
    CreateInterventionForm,
  },
  props: {
    invoiceId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      
      // States
      initializing: false,
      submitting: false,

      // Invoice
      invoice: null,

      // Intervention to create
      newIntervention: {},

      // ValidationErrors
      validationErrors: null,


    };
  },
  computed: {
    title() {
      return `Ajouter une intervention à la facture ${this.invoice ? this.invoice.ref : null}`;
    },
    canSubmit() {
      if (this.newIntervention.origin_code == null) {
        return false;
      }

      if (this.newIntervention.activity_ids == null) {
        return false;
      }

      if (this.newIntervention.activity_ids.length == 0) {
        return false;
      }

      if (this.newIntervention.location != null) {
        return this.newIntervention.location.street != null && this.newIntervention.location.zipcode != null && this.newIntervention.location.city != null && this.newIntervention.location.country_code != null;
        
      } else {
        return this.newIntervention.location_id != null;
      }
    },
    customer() {
      return this.invoice ? this.invoice.customer : null;
    },
  },
  async mounted() {
    this.initializing = true;

    try {

      // Load invoice

      const responseInvoice = await this.$services.invoice.retrieve(this.invoiceId);
      this.invoice = responseInvoice.data;
      this.newIntervention.invoice_id = this.invoice.id;
      this.newIntervention.origin_code = this.invoice.journal.origin.code;

      // Check if invoice already has an intervention

      if (this.invoice.interventions && this.invoice.interventions.length > 0) {

        // Load activities from the first intervention

        const intervention = this.invoice.interventions[0];
        this.newIntervention.activity_ids = intervention.activities.map((activity) => activity.id);
      }

    } finally {
      this.initializing = false;
    }

  },
  methods: {
    submit() {
      this.submitting = true;
      
      var data = {
        ...this.newIntervention,
        customer_id: this.invoice.customer.id,
        invoice_id: this.invoice.id,
      };

      this.$services.intervention.create(data)
        .then((response) => {
          this.$router.push({
            name: "intervention",
            params: {
              id: response.data.id,
            },
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },

  },
};
</script>