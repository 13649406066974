<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <i class="bi bi-file-earmark-text" />
    </template>

    <template #title>
      <p class="big">
        {{ contract.ref }}
      </p>
      <p class="small">
        Signé le {{ $filters.dateStringToHumanizedDate(contract.signature_date) }}
      </p>
      <p v-if="contract.cancelled" class="small text-red">
        Annulé
      </p>
    </template>

    <td>
      <router-link
        v-if="contract.customer"
        class="btn-link"
        :to="{ name: 'customer', params: { 'id': contract.customer.id } }"
        @click.stop>
        {{ $filters.printCustomer(contract.customer) }}
      </router-link>
    </td>

    <td>
      <span v-if="contract.author.id == contract.responsible.id" style="white-space: nowrap">
        {{ $filters.fullname(contract.author) }}
      </span>
      <span v-else-if="contract.responsible" style="white-space: nowrap">
        Auteur : {{ $filters.fullname(contract.author) }}
        <br>
        Responsable : {{ $filters.fullname(contract.responsible) }}
      </span>
      <span v-else style="white-space: nowrap">
        Auteur : {{ $filters.fullname(contract.author) }}
        <br>
        <i>Aucun responsable</i>
      </span> 
    </td>

    <td>
      <span v-if="contract.latest_intervention_rdv_datetime">
        {{ $filters.capitalize($filters.dateStringToHumanizedDate(contract.latest_intervention_rdv_datetime)) }}
      </span>
    </td>

    <td>
      <ContractProgressBar v-if="modelValue.iterations.length > 0" :iteration="modelValue.iterations[0]" />
    </td>

    <td>
      <span v-if="contract.afsca">Oui</span>
    </td>

    <template #toolButtons>
      <Toolbar>
        <template #default>
          <ContractToolButtons v-model="contract" />
        </template>
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import ContractToolButtons from "@/components/toolbuttons/ContractToolButtons.vue";
import { GBaseRow } from "g-list";
import Toolbar from "@/components/Toolbar.vue";
import ContractProgressBar from "@/components/others/ContractProgressBar.vue";

export default {
  name: "ContractRow",
  components: {
    ContractToolButtons,
    GBaseRow,
    Toolbar,
    ContractProgressBar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    contract: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "contract", params: { "id": this.modelValue.id } });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>