<template>
  <div>
    <div
      v-if="meIsAdmin"
      class="flow-spaced-container"
      style="margin: 10px;">
      <div
        v-for="(availableTechnician, index) in technicians"
        :key="index"
        style="width: 20%;">
        <div
          class="card clickable"
          :class="{ 'selected': technicianId == availableTechnician.id}"
          @click="selectTechnician(availableTechnician)">
          <div class="card-header">
            <div class="text-muted" style="display: flex; align-items: center;">
              <i class="bi bi-wallet" style="font-size: 16px; margin-right: 5px;" />
              Porte-monnaie de {{ $filters.fullname(availableTechnician) }}
            </div>
          </div>
          <div class="card-body">
            <span v-if="totalIntoTechniciansWallet[availableTechnician.id] != null">
              {{ totalIntoTechniciansWallet[availableTechnician.id] / 100 }}€
            </span>
            <LoaderView v-else />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="meIsTechnician"
      class="flow-spaced-container"
      style="margin: 10px;">
      <div
        style="width: 20%;">
        <div
          class="card">
          <div class="card-header">
            <div class="text-muted" style="display: flex; align-items: center;">
              <i class="bi bi-wallet" style="font-size: 16px; margin-right: 5px;" />
              Mon porte-monnaie
            </div>
          </div>
          <div class="card-body">
            <span v-if="totalIntoMyWallet != null">
              {{ totalIntoMyWallet / 100 }}€
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <span class="text-red">Vous devez être technicien ou administrateur pour voir cette page</span>
    </div>

    <router-view v-if="meIsAdmin" />

    <TechnicianWalletView v-else-if="meIsTechnician" />
  </div>
</template>

<script>

import { useTechniciansStore } from "@/stores/technicians";
import { useMeStore } from "@/stores/me";
import TechnicianWalletView from "@/views/cash/technicianWallet/TechnicianWalletView.vue";
import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "TechnicianWalletsLayout",
  components: {
    TechnicianWalletView,
    LoaderView,
  },
  data() {
    return {
      totalIntoTechniciansWallet: {},
      totalIntoMyWallet: null,
      techniciansStore: useTechniciansStore(),
      meStore: useMeStore(),
    };
  },
  computed: {
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
    technicianId() {
      return this.$route.params.technicianId;
    },
    me() {
      return this.meStore.getMe;
    },
    meIsAdmin() {
      return this.meStore.meIsAdmin;
    },
    meIsTechnician() {
      return this.meStore.meIsTechnician;
    },
  },
  watch: {
    technicians() {
      if (this.meIsAdmin && this.totalIntoTechniciansWallet == {}) {
        this.reload();
      } else if (this.meIsTechnician && this.totalIntoMyWallet == null) {
        this.reload();
      }
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    reload() {
      if (this.technicians == null) {
        return;
      }

      this.totalIntoTechniciansWallet = {};
      this.totalIntoMyWallet = null;

      if (this.meIsAdmin) {
        for (const technician of this.technicians) {
          this.$services.user.totalIntoWallet(technician.id)
            .then((total) => {
              this.totalIntoTechniciansWallet[technician.id] = total;
            });
        }
      } else if (this.meIsTechnician) {
        this.$services.user.totalIntoWallet(this.me.id)
          .then((total) => {
            this.totalIntoMyWallet = total;
          });
      }

      
    },
    selectTechnician(technician) {
      this.$router.push(
        {
          name: "technician-wallet",
          params: {
            technicianId: technician.id,
          },
        },
      );
    },
  },
};

</script>


<style lang="scss" scoped>

  .card {
    &.selected {
      border: 2px solid #0d6efd;
    }
  }

</style>
  