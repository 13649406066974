<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Intervention</span>
    </div>

    <LoaderView v-if="customer == null" />

    <div v-else-if="selectExistingIntervention" class="card-body">
      <div class="form-group" style="max-width: 300px;">
        <SelectObject
          type="intervention"
          :selectedValue="selectedIntervention ? selectedIntervention.ref_number : null"
          @select="onExistingInterventionSelect"
          @unselect="onExistingInterventionSelect(null)" />
        <small
          v-if="mode == 'invoice' && selectedIntervention != null && selectedIntervention.invoice != null"
          class="form-text text-red">
          Cette intervention est déjà liée à une facture
        </small>
        <small
          v-else-if="mode == 'quote' && selectedIntervention != null && selectedIntervention.quote != null"
          class="form-text text-red">
          Cette intervention est déjà liée à un devis
        </small>
      </div>
      <div>
        <button class="btn-link" @click="selectExistingIntervention = false">
          Créer une nouvelle intervention
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <div v-if="mode != 'intervention'">
        <button class="btn-link" @click="selectExistingIntervention = true">
          Sélectionner une intervention existante
        </button>
      </div>

      <CreateInterventionForm
        v-model="newIntervention"
        :customer="customer" />
    </div>

    <div v-if="customer" class="card-footer justify-right">
      <div class="flow-spaced-container">
        <button
          v-if="!selectExistingIntervention"
          class="btn btn-secondary"
          :disabled="!interventionIsValid || submitting"
          :class="{ 'loading': submitting }"
          @click="submitAndEnd">
          Créer l'intervention puis terminer
        </button>

        <button
          v-if="['quote', 'intervention'].includes(mode)"
          class="btn btn-primary"
          :disabled="!interventionIsValid || submitting"
          @click="submitAndGotoQuote">
          Créer un devis
        </button>

        <button
          v-if="['quote'].includes(mode)"
          class="btn btn-secondary"
          :disabled="submitting"
          @click="confirmNoInterventionThenGotoQuote">
          Créer un devis sans intervention
        </button>

        <button
          v-if="['invoice', 'intervention'].includes(mode)"
          class="btn btn-primary"
          :disabled="!interventionIsValid || submitting"
          @click="submitAndGotoInvoice">
          Créer une facture
        </button>

        <button
          v-if="['invoice'].includes(mode)"
          class="btn btn-secondary"
          :disabled="submitting"
          @click="confirmNoInterventionThenGotoInvoice">
          Créer une facture sans intervention
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import CreateInterventionForm from "@/components/forms/CreateInterventionForm.vue";
import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "InterventionCreationStepCard",
  components: {
    SelectObject,
    CreateInterventionForm,
    LoaderView,
  },
  data() {
    return {

      // Params
      customer: null,
      interventionToCopy: null,
      invoiceToCopy: null,
      quoteToCopy: null,

      // States
      initializing: false,
      submitting: false,

      // Intervention to create
      newIntervention: {},

      // SelectedIntervention
      selectedIntervention: null,

      // ValidationErrors
      validationErrors: null,

      // States
      selectExistingIntervention: this.$route.params.mode != "intervention",

    };
  },
  computed: {
    // Params

    mode() {
      return this.$route.params.mode;
    },
    customerId() {
      return this.$route.params.customerId;
    },
    defaultSelectedInterventionId() {
      return this.$route.query.defaultSelectedInterventionId;
    },
    interventionToCopyId() {
      return this.$route.query.interventionToCopyId;
    },
    invoiceToCopyId() {
      return this.$route.query.invoiceToCopyId;
    },
    quoteToCopyId() {
      return this.$route.query.quoteToCopyId;
    },
    interventionIsValid() {
      if (this.selectExistingIntervention) {
        if (this.selectedIntervention == null) {
          return false;
        } else if (this.mode == "invoice" && this.selectedIntervention.invoice != null) {
          return false;
        } else if (this.mode == "quote" && this.selectedIntervention.quote != null) {
          return false;
        }
        return true;
      } else {
        if (this.newIntervention.origin_code == null) {
          return false;
        }

        if (this.newIntervention.activity_ids == null) {
          return false;
        }

        if (this.newIntervention.activity_ids.length == 0) {
          return false;
        }

        if (this.newIntervention.location != null) {
          return this.newIntervention.location.street != null && this.newIntervention.location.zipcode != null && this.newIntervention.location.city != null && this.newIntervention.location.country_code != null;
        
        } else {
          return this.newIntervention.location_id != null;
        }

      }
    },
  },
  async mounted() {
    this.initializing = true;

    // Load params

    if (this.customerId == null) {
      throw new Error("customerId is required");
    }

    const customerResponse = await this.$services.customer.retrieve(this.customerId);
    this.customer = customerResponse.data;

    if (this.defaultSelectedInterventionId != null && this.interventionToCopyId != null && this.invoiceToCopyId != null && this.quoteToCopyId != null) {
      throw new Error("defaultSelectedInterventionId and interventionToCopyId and invoiceToCopyId and quoteToCopyId are mutually exclusive");
    
    } else if (this.defaultSelectedInterventionId != null) {
      const interventionResponse = await this.$services.intervention.retrieve(this.defaultSelectedInterventionId);
      this.selectedIntervention = interventionResponse.data;
      this.selectExistingIntervention = true;

    } else if (this.interventionToCopyId) {
      const interventionToCopyResponse = await this.$services.intervention.retrieve(this.interventionToCopyId);
      this.interventionToCopy = interventionToCopyResponse.data;

    } else if (this.invoiceToCopyId) {
      const invoiceResponse = await this.$services.invoice.retrieve(this.invoiceToCopyId);
      this.invoiceToCopy = invoiceResponse.data;

    } else if (this.quoteToCopyId) {
      const quoteResponse = await this.$services.quote.retrieve(this.quoteToCopyId);
      this.quoteToCopy = quoteResponse.data;
    }

    if (this.interventionToCopy) {
      this.newIntervention.origin_code = this.interventionToCopy.origin.code;
      this.newIntervention.activity_ids = this.interventionToCopy.activities.map((activity) => activity.id);

      if (this.interventionToCopy.technician) {
        this.newIntervention.technician_id = this.interventionToCopy.technician.id;
      }

      if (this.interventionToCopy.custom_duration) {
        this.newIntervention.custom_duration = this.interventionToCopy.custom_duration;
      }

      this.selectExistingIntervention = false;
    }

    if (this.customer.locations.length == 1) {
      this.newIntervention.location_id = this.customer.locations[0].id;
    }
  },
  methods: {
    confirmNoInterventionThenGotoInvoice() {
      const answer = window.confirm("Etes-vous sûr de vouloir créer une facture sans intervention ?");
      if (answer) {
        this.$router.push({
          name: "funnel.invoice",
          params: {
            customerId: this.customerId,
          },
          query: this.$route.query,

        });
      }
    },

    confirmNoInterventionThenGotoQuote() {
      const answer = window.confirm("Etes-vous sûr de vouloir créer un devis sans intervention ?");
      if (answer) {
        this.$router.push({
          name: "funnel.quote",
          params: {
            customerId: this.customerId,
          },
          query: this.$route.query,

        });
      }
    },

    // Events

    onExistingInterventionSelect(intervention) {
      this.selectedIntervention = intervention;
      this.selectExistingIntervention = true;
    },

    // Create intervention

    submitAndGotoInvoice() {
      this.submit()
        .then((intervention) => {
          this.$router.push({
            name: "funnel.intervention.invoice",
            params: {
              customerId: this.customerId,
              interventionId: intervention.id,
            },
            query: this.$route.query,
          });
        });
    },

    submitAndGotoQuote() {
      this.submit()
        .then((intervention) => {
          this.$router.push({
            name: "funnel.intervention.quote",
            params: {
              customerId: this.customerId,
              interventionId: intervention.id,
            },
            query: this.$route.query,
          });
        });
    },

    submitAndEnd() {
      this.submit()
        .then((intervention) => {
          this.$router.push({ name: "intervention", params: { id: intervention.id } });
        });
    },

    submit() {
      return new Promise((resolve, reject) => {
        if (!this.selectExistingIntervention) {
          this.createIntervention()
            .then((newIntervention) => {
              resolve(newIntervention);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(this.selectedIntervention);
        }
      });
    },

    createIntervention() {
      return new Promise((resolve, reject) => {
        this.submitting = true;
       
        if (this.newIntervention.location_id != null && this.newIntervention.location != null) {
          throw new Error("Both location_id and location are set");
        }

        var data = {
          ...this.newIntervention,
          customer_id: this.customerId,
        };

        this.$services.intervention.create(data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.validationErrors = error.response.data;
            }
            reject(error);
          })
          .finally(() => {
            this.submitting = false;
          });
      });
    },
  },
};
</script>
