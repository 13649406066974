<template>
  <table class="product-table desktop-only">
    <thead>
      <tr>
        <th class="shrink" />
        <th class="expand">
          Intitulé
        </th>
        <th class="shrink">
          Quantité
        </th>
        <th class="shrink">
          Prix €/unité
          <select
            v-model="internalPricesIncludesTaxes"
            class="form-select"
            :disabled="!canEditPrice"
            style="display: inherit; width: auto;">
            <option :value="true">
              TTC
            </option>
            <option :value="false">
              HT
            </option>
          </select>
        </th>
        <th class="shrink">
          Remise
        </th>
        <th class="shrink">
          Prix €/unité avec remise <span v-if="internalPricesIncludesTaxes">TTC</span><span v-else>HT</span>
        </th>
        <th class="shrink">
          Total ligne <span v-if="internalPricesIncludesTaxes">TTC</span><span v-else>HT</span>
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr style="text-align: center;">
        <td
          v-if="!internalItems || internalItems.length == 0"
          colspan="7">
          <i>Aucun produit. Commencez par ajouter quelques produits à cette facture.</i>
        </td>
      </tr>
      <tr v-for="(productLine, index) in internalItems" :key="index">
        <td>
          <button class="move-btn" :disabled="index == 0" @click="moveItemUp(index)">
            <i class="arrow up" />
          </button>

          <button class="move-btn" :disabled="index >= internalItems.length - 1" @click="moveItemDown(index)">
            <i class="arrow down" />
          </button>
        </td>

        <td>
          <input
            v-model="productLine.product_description"
            :class="{ 'invalid': productLine.product_description == null || productLine.product_description == '' }"
            type="text"
            class="form-control"
            style="width: 100%;">
        </td>

        <td>
          <input
            v-model="productLine.product_quantity"
            min="1"
            type="number"
            class="form-control"
            :class="{ 'invalid': productLine.product_quantity == null || productLine.product_quantity == '' || productLine.product_quantity < 1 }"
            style="width: 70px;"
            :disabled="!canEditPrice">
        </td>

        <td>
          <input
            v-model="productLine.product_price"
            type="number"
            class="form-control"
            :class="{ 'invalid': productLine.product_price == null || productLine.product_price == '' || productLine.product_price < 0 }"
            style="width: 100px;"
            :disabled="!canEditPrice">
        </td>
        <td>
          <div v-if="productLine.discount_percent == null" style="display: flex; align-items: center;">
            {{ internalDiscount }}%
            <button class="small-tool-btn" @click="productLine.discount_percent = internalDiscount">
              <i class="bi bi-pencil-square" />
            </button>
          </div>

          <div
            v-else
            style="display: flex; align-items: center;">
            <div class="input-group">
              <input
                v-model="productLine.discount_percent"
                type="number"
                class="form-control"
                :class="{ 'invalid': productLine.discount_percent == null || productLine.discount_percent == '' || productLine.discount_percent < 0 }"
                style="width: 60px;">
              <div class="input-group-append">
                %
              </div>
            </div>

            <button class="small-tool-btn" @click="productLine.discount_percent = null">
              <i class="bi bi-x-circle" />
            </button>
          </div>
        </td>

        <td style="text-align: center">
          <span v-if="!isNaN(productPriceExclTaxWithDiscount(productLine))">{{ productPriceExclTaxWithDiscount(productLine) / 100 }}€</span>
        </td>

        <td style="text-align: center">
          <span v-if="!isNaN(totalLineExclTaxWithDiscount(productLine))">{{ totalLineExclTaxWithDiscount(productLine) / 100 }}€</span>
        </td>

        <td>
          <button
            :disabled="!canRemoveProducts"
            style="cursor: pointer;"
            @click="removeItem(index)">
            <TrashIcon />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="product-table mobile-only">
    <tr style="text-align: center;">
      <td
        v-if="!internalItems || internalItems.length == 0"
        colspan="7">
        <i>Aucun produit. Commencez par ajouter quelques produits à cette facture.</i>
      </td>
    </tr>
    <tr v-for="(productLine, index) in internalItems" :key="index">
      <td class="shrink">
        <button class="move-btn" :disabled="index == 0" @click="moveItemUp(index)">
          <i class="arrow up" />
        </button>
        <button class="move-btn" :disabled="index >= internalItems.length - 1" @click="moveItemDown(index)">
          <i class="arrow down" />
        </button>
      </td>
      <td class="expand">
        <input v-model="productLine.product_description" type="text" class="form-control" style="width: 100%;">
        <div class="mobile-row">
          <input v-model="productLine.product_quantity" type="number" class="form-control" style="width: 70px;">
          x
          <input v-model="productLine.product_price" type="number" class="form-control" style="width: 100px;">
          -
          {{ internalDiscount }}%
          =
          <span>{{ totalLineExclTaxWithDiscount(productLine) / 100 }}€</span>
        </div>
      </td>
      <td class="shrink">
        <button style="cursor: pointer;" :disabled="!canRemoveProducts" @click="removeItem(index)">
          <TrashIcon />
        </button>
      </td>
    </tr>
  </table>
  <div v-if="canAddProducts" class="flow-spaced-container">
    <SelectObject type="product" style="flex-grow: 1;" @select="addProductLine" />
    <button
      class="btn-secondary"
      @click="addProductLine">
      Produit personnalisé
    </button>
  </div>
  <table class="price-table">
    <tr style="text-align: right">
      <td>
        Remise générale
      </td>
      <td class="input-group">
        <input
          v-model="internalDiscount"
          type="number"
          class="form-control"
          :class="{ 'invalid': internalDiscount === null || internalDiscount === '' || internalDiscount < 0 }"
          min="0"
          max="100"
          :disabled="internalPricesIncludesTaxes || !canEditPrice"
          style="width: 70px;"
          @blur="if (internalDiscount == '') internalDiscount = 0;">
        <div class="input-group-append">
          %
        </div>
      </td>
    </tr>
    <tr style="text-align: right">
      <td>Sous total HTVA</td>
      <td>
        <span v-if="!isNaN(totalExclTax)">{{ totalExclTax / 100 }} €</span>
      </td>
    </tr>
    <tr style="text-align: right">
      <td>TVA ({{ taxRate ? taxRate : 'Inconnu' }}%)</td>
      <td><span v-if="!isNaN(totalTax)">{{ totalTax / 100 }} €</span></td>
    </tr>
    <tr style="text-align: right">
      <td><strong>Total TVA</strong></td>
      <td><strong><span v-if="!isNaN(totalInclTax)">{{ totalInclTax / 100 }} €</span></strong></td>
    </tr>
  </table>
</template>
  
<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import TrashIcon from "@/assets/iconsVue/TrashIcon.vue";

export default {
  name: "InvoiceProductsForm",
  components: {
    SelectObject,
    TrashIcon,
  },
  props: {
    products: {
      type: Array,
      required: false,
      default: () => { return []; },
    },
    discountPercent: {
      type: Number,
      required: false,
      default: 0,
    },
    taxRate: {
      type: Number,
      required: false,
      default: null,
    },
    pricesIncludesTax: {
      type: Boolean,
      required: true,
      default: false,
    },
    canAddProducts: {
      type: Boolean,
      required: false,
      default: true,
    },
    canRemoveProducts: {
      type: Boolean,
      required: false,
      default: true,
    },
    canEditPrice: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["update:products", "update:discountPercent", "total", "update:pricesIncludesTax"],
  data() {
    return {
      internalItems: this.products,
      internalDiscount: this.discountPercent,
      internalDeliveryFee: this.deliveryFee,
    };
  },
  computed: {
    internalPricesIncludesTaxes: {
      get() {
        return this.pricesIncludesTax;
      },
      set(val) {
        this.$emit(
          "update:pricesIncludesTax",
          val,
        );
      },
    },
    totalExclTax() {// in cents
      if (!this.internalItems) {
        return 0;
      } else if (this.internalPricesIncludesTaxes) {
        return NaN;
      } else {
        var total = 0;
        for (const productLine of this.internalItems) {
          total = total + this.totalLineExclTaxWithDiscount(productLine);
        }
        return total;
      }
    
    },
    totalTax() { // In cents
      if (this.taxRate == null) {
        return NaN;
      } else if (!this.internalItems) {
        return 0;
      } else if (this.internalPricesIncludesTaxes) {
        return NaN;
      } else {
        var total = 0;
        for (const productLine of this.internalItems) {
          total = total + this.totalLineTax(productLine);
        }
        return total;
      }
    },
    totalInclTax() { // In cents
      if (this.taxRate == null) {
        return NaN;
      } else if (!this.internalItems) {
        return 0;
      } else {
        var total = 0;
        for (const productLine of this.internalItems) {
          total = total + this.totalLineInclTaxWithDiscount(productLine);
        }
        return total;
      }
    },
  },
  watch: {
    products(newVal) {
      this.internalItems = newVal;
    },
    discountPercent(newVal) {
      this.internalDiscount = newVal;
    },
    internalItems(newVal) {
      this.$emit(
        "update:products",
        newVal,
      );
    },
    internalDiscount(newVal) {
      
      if (newVal == "") {
        newVal = 0;
      }
      
      this.$emit(
        "update:discountPercent",
        newVal,
      );
    },
    totalInclTax(newVal) {
      this.$emit(
        "total",
        newVal,
      );
    },
  },
  methods: {
    refreshProducts() {
      this.$emit(
        "update:products",
        this.internalItems,
      );
    },
    addProductLine(product = null) {
      if (!this.internalItems) {
        this.internalItems = [];
      }

      if (product) {
        this.internalItems.push({
          product_id: product.id,
          product_description: product.public_name ? product.public_name : product.name,
          product_quantity: 1,
          product_price: product.sale_price_per_unit,
        });
      } else {
        this.internalItems.push({
          product_quantity: 1,
        });
      }

      this.refreshProducts();
    },
    removeItem(index) {
      this.internalItems.splice(index, 1);
      this.refreshProducts();
    },
    productPriceExclTaxWithDiscount(productLine) {
      const priceExclTax = productLine.product_price * 100;
      const discountPercent = productLine.discount_percent != null ? productLine.discount_percent : this.internalDiscount;
      const priceExclTaxAfterDiscount = priceExclTax - (priceExclTax * discountPercent / 100);
      return Math.round(priceExclTaxAfterDiscount);
    },
    totalLineExclTaxWithDiscount(productLine) {
      return this.productPriceExclTaxWithDiscount(productLine) * productLine.product_quantity;
    },
    totalLineTax(productLine) {
      const totalLineExclTaxWithDiscount = this.totalLineExclTaxWithDiscount(productLine);
      const totalLineTax = totalLineExclTaxWithDiscount * this.taxRate / 100;
      return Math.round(totalLineTax);
    },
    totalLineInclTaxWithDiscount(productLine) {
      if (this.internalPricesIncludesTaxes) {
        return this.totalLineExclTaxWithDiscount(productLine);
      } else {
        return this.totalLineExclTaxWithDiscount(productLine) + this.totalLineTax(productLine);
      }
    },
    moveItemUp(index) {
      if (index <= 0 || index >= this.internalItems.length) {
        return;
      }
      
      var b = this.internalItems[index];
      this.internalItems[index] = this.internalItems[index - 1];
      this.internalItems[index - 1] = b;
    },
    moveItemDown(index) {
      if (index < 0 || index >= this.internalItems.length - 1) {
        return;
      }

      var b = this.internalItems[index];
      this.internalItems[index] = this.internalItems[index + 1];
      this.internalItems[index + 1] = b;
    },
  },
};
</script>
<style lang="scss" scoped>

  .product-table,
  .price-table {

    td,
    th {
      padding: 5px;
      align-items: center;
    }
  }

  .product-table {
    width: 100%;
  }

  .price-table {
    margin-left: auto;
  }

  .mobile-row {
    display: flex;
    align-items: center;
    margin: 10px -5px 0 -5px;
    flex-wrap: nowrap;
    
    * {
      margin: 0 5px;
    }
    
  }

  .move-btn {
    width: 22px;
    height: 18px;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:disabled) {
      cursor: pointer;

      &:hover {
        color: black;
      }
    }

    &:disabled {
      color: lightgray;
    }
  }

  .form-control {
    &.invalid {
      border-color: red;
    }
  }

</style>