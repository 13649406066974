<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            placeholder="Référence, client..."
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Date de création
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Auteur
          </div>
          <TechnicianSelect v-model="authorId" allOption />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Responsable
          </div>
          <TechnicianSelect v-model="responsibleId" allOption />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option :value="null">
              Date de création (Plus réçent d'abord)
            </option>
            <option value="created_at">
              Date de création (Plus ancien d'abord)
            </option>
            <option value="-latest_intervention_rdv_datetime">
              Date de dernière intervention (Plus réçent d'abord)
            </option>
            <option value="latest_intervention_rdv_datetime">
              Date de dernière intervention (Plus ancien d'abord)
            </option>
          </select>
        </div>

        <div class="filter-item">
          <ResetFiltersButton :store="store" />
        </div>
      </div>
    
      <button
        class="tool-btn tooltip"
        @click="$router.push({ name: 'create-contract' })">
        <i class="bi bi-file-earmark-text" />
        <div class="tooltip-text left">
          Nouveau contrat
        </div>
      </button>
    </div>

    <PaginatedStoreList
      :rowComponent="rowComponent"
      :columnsNames="[null, 'Client', 'Resonsable', 'Dernière int.', 'Progression', 'Afsca']"
      :store="store" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import ContractRow from "@/components/rows/contract/ContractRow.vue";
import { shallowRef } from "vue";
import PaginatedStoreList from "@/components/listV2/PaginatedStoreList.vue";
import { useContractListStore } from "@/stores/contractList";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";

export default {
  name: "ContractListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    PaginatedStoreList,
    DateRangeInput,
    TechnicianSelect,
  },
  data() {
    return {
      rowComponent: shallowRef(ContractRow),
      store: useContractListStore(),
    };
  },
  computed: {
    createdDateRange: {
      get() {
        return [
          this.store.getFilter("created_at_after"),
          this.store.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.store.addFilter("created_at_after", val[0]);
        this.store.addFilter("created_at_before", val[1]);
        this.store.refresh();
      },
    },
    query: {
      get() {
        return this.store.getFilter("q");
      },
      set(val) {
        this.store.addFilter("q",  val);
        this.store.refresh();
      },
    },
    sortBy: {
      get() {
        return this.store.getFilter("sort_by");
      },
      set(val) {
        this.store.addFilter("sort_by", val);
        this.store.refresh();
      },
    },
    authorId: {
      get() {
        return this.store.getFilter("author_id");
      },
      set(val) {
        this.store.addFilter("author_id", val);
        this.store.refresh();
      },
    },
    responsibleId: {
      get() {
        return this.store.getFilter("responsible_id");
      },
      set(val) {
        this.store.addFilter("responsible_id", val);
        this.store.refresh();
      },
    },
  },
};
</script>

<style lang="scss" scoped>


</style>
