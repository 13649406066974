<template>
  <div class="card">
    <GFilterBar>
      <template #filter>
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            :placeholder="'Nom, sku...'"
            @input="onQuery" />
        </div>
      </template>

      <template #buttons>
        <button
          class="tool-btn tooltip"
          @click="$router.push({ name: 'scan-products' })">
          <i class="bi bi-box-arrow-in-down" />
          <div class="tooltip-text left">
            Importer des produits
          </div>
        </button>
      </template>
    </GFilterBar>
      
    <GLocalList
      ref="list"
      :rowComponent="rowComponent"
      :loadFuntion="loadBatches" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import { GFilterBar } from "g-list";
import {GLocalList} from "g-list";
import BatchRow from "@/components/rows/batch/BatchRow.vue";
import { shallowRef } from "vue";

export default {
  name: "StorageContentView",
  components: {
    SearchInput,
    GFilterBar,
    GLocalList,
  },
  data() {
    return {
      query: null,
      rowComponent: shallowRef(BatchRow),
    };
  },
  computed: {
    storageId() {
      return this.$route.params.id;
    },
  },
  watch: {
    storageId(newVal) {
      if (newVal != null) {
        this.refresh();
      }
    },
  },
  methods: {
    loadBatches(limit, page) {
      return this.$services.batch.list({ q: this.query, storage_id: this.storageId }, limit, page);
    },
    refresh() {
      this.$refs.list.refreshResultList();
    },
    onQuery(query) {
      this.query = query ? query.target.value : null;
      this.refresh();
    },
  },
};
</script>

