<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="card">
    <div class="card-header">
      De {{ ovhEmail.sender }} à {{ ovhEmail.receiver }} le {{ ovhEmail.date }} : {{ ovhEmail.subject }}
    </div>

    <div class="card-body">
      <iframe v-if="ovhEmail.html" ref="iframe" style="width: 100%; border: none; max-height: 200px; overflow: hidden;" />
      <div v-else-if="ovhEmail.text" style="max-height: 200px; overflow: hidden;" v-html="ovhEmail.text" />
      <div v-else>
        <i>Aucun message</i>
      </div>
      
      <button
        class="btn-link"
        @click="seeMore">
        Voir plus
      </button>
    </div>

    <Modal ref="modal" width="full">
      <div class="vertical-spaced-container" style="height: 100%;">
        <div class="info-grid-layout">
          <div>De</div>
          <div>{{ ovhEmail.sender }}</div>
          <div>À</div>
          <div>{{ ovhEmail.receiver }}</div>
          <div>Date</div>
          <div>{{ ovhEmail.date }}</div>
          <div>Sujet</div>
          <div>{{ ovhEmail.subject }}</div>
        </div>

        <iframe v-if="ovhEmail.html" ref="modalIframe" style="width: 100%; border: none; height: 100%; overflow: hidden;" />
        <div v-else-if="ovhEmail.text" v-html="ovhEmail.text" />
        <div v-else>
          <i>Aucun message</i>
        </div>
      </div>
    
      <template #buttons>
        <button
          class="btn-secondary"
          @click="$refs.modal.hide()">
          Ok
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "CustomerOvhEmailCard",
  components: {
    Modal,
  },
  props: {
    ovhEmail: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.ovhEmail.html) {
        const doc = this.$refs.iframe.contentDocument;
        doc.open();
        doc.write(this.ovhEmail.html);
        doc.close();
      }
    });
  },
  methods: {
    seeMore() {
      if (this.ovhEmail.html) {
        const docInModal = this.$refs.modalIframe.contentDocument;
        docInModal.open();
        docInModal.write(this.ovhEmail.html);
        docInModal.close();
      }

      this.$refs.modal.show();
    },
  },
  

};

</script>