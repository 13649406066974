<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            :placeholder="'Numéro, nom, email, téléphone...'"
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option :value="null">
              Date de création (Plus réçent d'abord)
            </option>
            <option value="created_at">
              Date de création (Plus ancien d'abord)
            </option>
          </select>
        </div>

        <div class="filter-item">
          <ResetFiltersButton :store="quoteListStore" />
        </div>
      </div>

      <button
        class="tool-btn tooltip"
        @click="$router.push({ name: 'funnel.customer', params: { mode: 'quote'} })">
        <InvoiceIcon status="quote" />
        <div class="tooltip-text left">
          Nouveau devis
        </div>
      </button>
    </div>

    <div class="cat-list" style="margin: 5px;">
      <router-link
        :to="{ name: 'quote-list' }"
        class="cat-list-item"
        :class="{ 'active': !$route.query.originCode }">
        Tous
        <span
          v-if="quoteListStore.getTotal('all') != null">
          ({{ quoteListStore.getTotal('all') }})
        </span>
      </router-link>
      
      <router-link
        v-for="(origin, index) in origins"
        :key="index"
        :to="{ name: 'quote-list', query: { originCode: origin.code } }"
        class="cat-list-item"
        :class="{ 'active': $route.query.originCode == origin.code }">
        {{ origin.code }}
        <span
          v-if="quoteListStore.getTotal(origin.code) != null">
          ({{ quoteListStore.getTotal(origin.code) }})
        </span>
      </router-link>
    </div>

    <router-view style="min-height: 100%;" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import { useQuoteListStore } from "@/stores/quoteList";
import { useTechniciansStore } from "@/stores/technicians";
import { useOriginsStore } from "@/stores/origins";

export default {
  name: "QuoteListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    DateRangeInput,
    InvoiceIcon,
  },
  data() {
    return {
      quoteListStore: useQuoteListStore(),
      techniciansStore: useTechniciansStore(),
      originsStore: useOriginsStore(),
    };
  },
  computed: {
    query: {
      get() {
        return this.quoteListStore.getFilter("q");
      },
      set(val) {
        this.quoteListStore.addFilter("q", val);
        this.quoteListStore.refresh();
      },
    },
    createdDateRange: {
      get() {
        return [
          this.quoteListStore.getFilter("created_at_after"),
          this.quoteListStore.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.quoteListStore.addFilter("created_at_after", val[0]);
        this.quoteListStore.addFilter("created_at_before", val[1]);
        this.quoteListStore.refresh();
      },
    },
    sortBy: {
      get() {
        return this.quoteListStore.getFilter("sort_by");
      },
      set(val) {
        this.quoteListStore.addFilter("sort_by", val);
        this.quoteListStore.refresh();
      },
    },
    origins() {
      return this.originsStore.getOrigins;
    },    
  },
};
</script>

<style lang="scss" scoped>


</style>
