<template>
  <div class="card" style="margin: 10px;">
    <div class="card-header">
      <div class="text-muted" style="display: flex; align-items: center;">
        <i class="bi bi-box-arrow-in-right" style="font-size: 16px; margin-right: 5px;" />
        Paiements en liquide reçus par {{ name }}
      </div>
    </div>

    <div class="card-body">
      <LocalList
        ref="cashPaymentList"
        :rowComponent="rowComponent"
        noResultMessage="Aucun paiement reçu"
        :load="loadCashPaymentsInTechnicianWallet"
        :columnsNames="['Montant', 'Facture', 'Technicien', 'Statut']" />
    </div>
  </div>
</template>

<script>

import LocalList from "@/components/listV2/LocalList.vue";
import CashPaymentRow from "@/components/rows/cashTransaction/CashPaymentRow.vue";
import { shallowRef } from "vue";
import { useMeStore } from "@/stores/me";

export default {
  name: "TechnicianWalletView",
  components: {
    LocalList,
  },
  data() {
    return {
      rowComponent: shallowRef(CashPaymentRow),
      technician: null,
      totalIntoTechniciansWallet: {},
      meStore: useMeStore(),
    };
  },
  computed: {
    technicianId() {
      const paramTechnicianId = this.$route.params.technicianId;
      if (paramTechnicianId == null) {
        return this.me.id;

      } else {
        return paramTechnicianId;
      }
    },
    me() {
      return this.meStore.getMe;
    },
    name() {
      if (this.$route.params.technicianId != null) {
        if (this.technician) {
          return this.technician.first_name;
        } else {
          return "...";
        }
      } else {
        return "moi";
      }
    },
  },
  watch: {
    technicianId() {
      this.loadTechnician();
    },
  },
  mounted() {
    this.loadTechnician();
  },
  methods: {
    loadTechnician() {
      if (this.$route.params.technicianId == null) {
        return;
      }

      this.$services.user.retrieve(this.$route.params.technicianId)
        .then((response) => {
          this.technician = response.data;
        });
    },
    // eslint-disable-next-line no-unused-vars
    loadCashPaymentsInTechnicianWallet(page) {
      return this.$services.payment.listCashPaymentsInTechnicianWallet(this.technicianId);
    },
  },
};

</script>


<style lang="scss" scoped>

  .card {
    &.selected {
      border: 2px solid #0d6efd;
    }
  }

</style>
  