import { api } from "@/api";
import { defineStore } from "pinia";

export interface State {
  me: any;
  accessToken: string | null;
  refreshToken: string | null;
}

export const useMeStore = defineStore("me", {
  state: (): State => ({
    me: null,
    accessToken: null,
    refreshToken: null,
  }),
  getters: {
    getMe: state => {
      return state.me;
    },
    meIsSuperAdmin: state => {
      return state.me.is_superuser;
    },
    meIsAdmin: state => {
      return state.me.is_staff;
    },
    meIsTechnician: state => {
      return state.me.is_technician;
    },
    meIsManager: state => {
      return state.me.is_manager;
    },
    meIsSecretary: state => {
      return state.me.is_secretary;
    },
    meIsShopManager: state => {
      return state.me.is_shop_manager;
    },
    isLoggedIn: state => {
      return state.accessToken != null && state.refreshToken != null;
    },
    hasRefreshToken: state => {
      return state.refreshToken != null;
    },
  },
  actions: {
    clear() {
      this.me = null;
      this.accessToken = null;
      this.refreshToken = null;
    },
    setTokens(accessToken: string, refreshToken: string) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    },
    fetchMe() {
      return api.get("/me/")
        .then((response) => {
          this.me = response.data;
        });
    },
    login(payload: { email: string; password: string; }) {
      return api.post("/auth/token/", payload)
        .then((response) => {
          this.setTokens(response.data.access, response.data.refresh);
        });
    },
    logout() {
      this.clear();
    },
    loadTokensFromLocalStorage() {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      if (accessToken && refreshToken) {
        this.setTokens(accessToken, refreshToken);
      }
    },
    refreshAccessToken() {
      return new Promise((resolve, reject) => {
        api.post("/auth/token/refresh/", { refresh: this.refreshToken })
          .then((response) => {
            const newAccessToken: string = response.data.access;
            const newRefreshToken: string = response.data.refresh;

            this.setTokens(newAccessToken, newRefreshToken);
            
            resolve(newAccessToken);
          }).catch((error) => {
            reject(error);
          });
      });
    },
  },
});

