<template>
  <Modal ref="modal">
    <p v-if="intervention.invoice != null" class="text-red">
      Vous n'êtes pas autorisé à modifier l'origine de cette intervention car elle a déjà été facturée
    </p>

    <OriginInput v-else v-model="origin" />

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="loading"
        @click="hide">
        <span v-if="intervention.invoice != null">Fermer</span>
        <span v-else>Annuler</span>
      </button>
      <button
        v-if="intervention.invoice != null"
        class="btn-primary"
        :class="{ 'loading': loading }"
        :disabled="loading"
        style="margin-left: 5px;"
        @click="submit">
        Enregistrer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import OriginInput from "@/components/inputs/OriginInput.vue";

export default {
  name: "EditInterventionOriginModal",
  components: {
    Modal,
    OriginInput,
  },

  emits: ["change"],
  data() {
    return {
      intervention: null,
      origin: [],
      loading: false,
    };
  },
  methods: {
    show(intervention) {
      this.intervention = intervention;
      this.origin = intervention.origin;
      this.loading = false;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.intervention = null;
      this.origin = null;
      this.loading = false;
    },
    submit() {
      this.loading = true;
      
      this.$services.intervention.patch(
        this.intervention.id,
        {
          origin_code: this.origin.code,
        },
      )
        .then((response) => {
          this.$emit(
            "change",
            {
              origin: response.data.origin,
            },
          );
          this.hide();
        });
    },
    
  },
};

</script>

<style lang="scss" scoped>


</style>