<template>
  <BaseDropdown>
    <template #component>
      <div class="btn-primary">
        +
      </div>
    </template>

    <SimpleButton
      icon="bi bi-people-fill"
      text="Client"
      @click="goto('create-customer')" />

    <SimpleButton
      icon="bi bi-person-fill"
      text="Intervention"
      @click="$router.push({ name: 'funnel.customer', params: { mode: 'intervention' }})" />

    <SimpleButton
      icon="bi bi-file-earmark-text-fill"
      text="Devis"
      @click="$router.push({ name: 'funnel.customer', params: { mode: 'quote' }})" />

    <SimpleButton
      icon="bi bi-file-earmark-check-fill"
      text="Facture"
      @click="$router.push({ name: 'funnel.customer', params: { mode: 'invoice' }})" />

    <SimpleButton
      icon="bi bi-upc-scan"
      text="Scanner"
      @click="goto('scan-product')" />
  </BaseDropdown>
</template>

<script>

import BaseDropdown from "@/components/vueComponents/base/BaseDropdown.vue";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";

export default {
  name: "FloatingBar",
  components: {
    BaseDropdown,
    SimpleButton,
  },
  emits: ["toggleDropdownMenu"],
  methods: {
    goto(route) {
      if (this.$route.name != route) {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    
  :deep(.dropdown-menu) {
    border: #b25441 5px solid;
  }

</style>
