import type { RouteRecordRaw } from "vue-router";

import Funnel from "@/views/funnel/Funnel.vue";
import InterventionCreationStepCard from "@/views/funnel/InterventionCreationStepCard.vue";
import CustomerCreationStepCard from "@/views/funnel/CustomerCreationStepCard.vue";
import InvoiceCreationStepCard from "@/views/funnel/InvoiceCreationStepCard.vue";


const funnelRoutes: RouteRecordRaw[] = [
  {
    path: "/funnel",
    component: Funnel,
    abstract: true,
    name: "funnel",
    children: [
      {
        path: "/funnel/:mode/client",
        component: CustomerCreationStepCard,
        name: "funnel.customer",
      },
      {
        path: "/funnel/:mode/client/:customerId/intervention",
        component: InterventionCreationStepCard,
        name: "funnel.intervention",
      },
      {
        path: "/funnel/client/:customerId/intervention/:interventionId/facture",
        component: InvoiceCreationStepCard,
        name: "funnel.intervention.invoice",
        props: {
          mode: "invoice",
        },
      },
      {
        path: "/funnel/client/:customerId/facture",
        component: InvoiceCreationStepCard,
        name: "funnel.invoice",
        props: {
          mode: "invoice",
        },
      },
      {
        path: "/funnel/client/:customerId/intervention/:interventionId/devis",
        component: InvoiceCreationStepCard,
        name: "funnel.intervention.quote",
        props: {
          mode: "quote",
        },
      },
      {
        path: "/funnel/client/:customerId/devis",
        component: InvoiceCreationStepCard,
        name: "funnel.quote",
        props: {
          mode: "quote",
        },
      },
    ],
  },
];

export default funnelRoutes;
