<template>
  <ObjectViewLayout
    :title="title"
    withBackButton
    hideHeaderOnMobile
    @back="$router.push({ name: 'intervention-list' })">
    <div class="flex justify-center mb-5">
      <Steps :steps="steps" style="flex-grow: 1; max-width: 500px;" @goto="gotoStep" />
    </div>

    <router-view v-slot="{ Component }">
      <transition name="tunnel-transition" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import Steps from "@/components/baseComponents/Steps.vue";

export default {
  name: "Funnel",
  components: {
    ObjectViewLayout,
    Steps,
  },
  data() {
    return {
      customer: null,
      intervention: null,
    };
  },
  computed: {
    route() {
      return this.$route.name;
    },
    mode() {
      return this.$route.params.mode;
    },
    steps() {
      return [
        { id: "customer", name: this.customerStepTitle, href: "#", status: this.route == "funnel.customer" ? "current" : "complete" },
        { id: "intervention", name: this.interventionStepTitle, href: "#", status: this.route == "funnel.intervention" ? "current" : this.route == "funnel.customer" ? "upcoming" : "complete" },
        { id: "invoice", name: this.route == "funnel.quote" || this.route == "funnel.intervention.quote" ? "Devis" : "Facture", href: "#", status: this.route == "funnel.invoice" || this.route == "funnel.quote" ? "current" : "upcoming" },
      ];
    },
    title() {
      var prefix = "";
      switch (this.mode) {
      case "intervention":
        prefix = "Créer une intervention";
        break;
      case "invoice":
        prefix = "Créer une facture";
        break;
      case "quote":
        prefix = "Créer un devis";
        break;
      case undefined:
        if (this.route == "funnel.quote" || this.route == "funnel.intervention.quote") {
          prefix = "Créer un devis";
        } else {
          prefix = "Créer une facture";
        }

        break;
      }

      var suffix = "";

      if (this.$route.query.interventionToCopyId != null) {
        suffix = " à partir d'une autre intervention";
      } else if (this.$route.query.invoiceToCopyId != null) {
        suffix = " à partir d'une autre facture";
      } else if (this.$route.query.quoteToCopyId != null) {
        suffix = " à partir d'un autre devis";
      }

      return `${prefix} ${suffix}`;
    },
    customerId() {
      return this.$route.params.customerId;
    },
    customerStepTitle() {
      if (this.customer) {
        return `Client ${this.customer.billing_person_last_name} sélectionné`;
      } else {
        return "Sélectionnez un client";
      }
    },
    interventionId() {
      return this.$route.params.interventionId;
    },
    interventionStepTitle() {
      if (this.intervention) {
        return `Intervention ${this.intervention.ref_number} sélectionnée`;
      } else {
        return "Aucune intervention sélectionnée";
      }
    },
  },
  watch: {
    customerId() {
      this.fetchCustomer();
    },
    interventionId() {
      this.fetchIntervention();
    },
  },
  mounted() {
    this.fetchCustomer();
    this.fetchIntervention();
  },
  methods: {
    async fetchCustomer() {
      if (this.customerId) {
        const customerResponse = await this.$services.customer.retrieve(this.customerId);
        this.customer = customerResponse.data;
      }
    },
    async fetchIntervention() {
      if (this.interventionId) {
        const interventionResponse = await this.$services.intervention.retrieve(this.interventionId);
        this.intervention = interventionResponse.data;
      }
    },

    gotoStep(stepId) {
      
    },

  },
};
</script>