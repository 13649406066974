<template>
  <button
    class="tool-btn tooltip"
    @click.stop="exportPDF">
    <Loader v-if="fetchingPDF" />
    <i v-else class="bi bi-download" />
    <div class="tooltip-text">
      Télécharger
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null"
    @click.stop="$refs.sendEmailModal.show()">
    <i class="bi bi-chat" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer un email au client
      </div>
      <div v-if="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null" class="tooltip-text-red">
        Aucun email associé au client
      </div>
    </div>
    <SendEmailToCustomerModal v-if="modelValue.customer" ref="sendEmailModal" :customer="modelValue.customer" />
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="sendingInvoiceToCustomerByEmail || (!modelValue.contact?.email && !modelValue.customer?.contact?.email)"
    @click.stop="sendInvoiceToCustomerByEmail">
    <Loader v-if="sendingInvoiceToCustomerByEmail" />
    <i v-else class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div v-if="modelValue.send_date" class="tooltip-text">
        Ré-envoyer la facture au client par email
      </div>
      <div v-else class="tooltip-text">
        Envoyer la facture au client par email
      </div>
      <div v-if="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null" class="tooltip-text-red">
        Aucun email associé au client
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="sendInvoiceToCustomerByMail">
    <Loader v-if="sendingInvoiceToCustomerByMail" />
    <i v-else class="bi bi-envelope-paper" />
    <div class="tooltip-text">
      <span v-if="modelValue.send_date">Ré-envoyer la facture au client par courrier</span>
      <span v-else>Envoyer la facture au client par courrier</span>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="(modelValue.contact?.email == null && modelValue.customer?.contact?.email == null) || modelValue.third_payment_request_send_date != null"
    @click.stop="sendPaymentRequestToCustomer">
    <Loader v-if="sendingPaymentRequestToCustomerByEmail" />
    <EnvelopeIcon v-else type="exclamation" />
    <div class="tooltip-container">
      <div v-if="modelValue.third_payment_request_send_date" class="tooltip-text-red">
        Les 3 rappels ont déjà été envoyés
      </div>
      <div v-else-if="modelValue.second_payment_request_send_date" class="tooltip-text">
        Envoyer le 3ème rappel au client
      </div>
      <div v-else-if="modelValue.first_payment_request_send_date" class="tooltip-text">
        Envoyer le 2ème rappel au client
      </div>
      <div v-else class="tooltip-text">
        Envoyer le 1er rappel au client
      </div>
      <div v-if="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null" class="tooltip-text-red">
        Aucun email associé au client
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="clone">
    <InvoiceIcon status="invoice" />
    <div class="tooltip-text">
      Copier la facture
    </div>
  </button>

  <router-link
    :to="{ name: 'add-intervention-to-invoice', params: { id: modelValue.id } }"
    class="tool-btn tooltip">
    <i class="bi bi-briefcase" />
    <div class="tooltip-text">
      Ajouter une nouvelle intervention à cette facture
    </div>
  </router-link>

  <button
    class="tool-btn tooltip"
    :disabled="!modelValue.is_locked"
    @click.stop="createCreditNote">
    <InvoiceIcon
      status="creditNote" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Créer une note de crédit
      </div>
      <div v-if="!modelValue.is_locked" class="tooltip-text-red">
        Facture pas encore vérrouillée, vous pouvez directement la modifier
      </div>
    </div>
  </button>

  <router-link
    v-if="modelValue.contract"
    :to="{ name: 'contract', params: { id: modelValue.contract.contract.id } }"
    class="tool-btn tooltip">
    <InvoiceIcon status="contract" />
    <div class="tooltip-text">
      Voir le contrat
    </div>
  </router-link>

  <button
    v-else
    class="tool-btn tooltip"
    @click.stop="createContract">
    <InvoiceIcon
      status="contract" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Créer un contrat
      </div>
    </div>
  </button>

  <SimpleButton
    v-if="meIsAdmin"
    icon="bi bi-window"
    tooltipText="Voir dans l'interface admin"
    @click.stop="goToAdminPage" />

  <CreateContractModal
    ref="createContractModal"
    @created="onContractCreated" />
</template>

<script>

import SendEmailToCustomerModal from "@/components/modals/SendEmailToCustomerModal.vue";
import Loader from "@/components/Loader.vue";
import EnvelopeIcon from "@/assets/iconsVue/EnvelopeIcon.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import CreateContractModal from "@/components/modals/CreateContractModal.vue";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";
import { useMeStore } from "@/stores/me";


export default {
  name: "InvoiceToolButtons",
  components: {
    SendEmailToCustomerModal,
    Loader,
    EnvelopeIcon,
    InvoiceIcon,
    CreateContractModal,
    SimpleButton,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      fetchingPDF: false,
      sendingInvoiceToOdoo: false,
      sendingInvoiceToCustomerByEmail: false,
      sendingPaymentRequestToCustomerByEmail: false,
      sendingInvoiceToCustomerByMail: false,
      meStore: useMeStore(),
    };
  },
  computed: {
    meIsAdmin() {
      return this.meStore.meIsAdmin;
    },
  },
  methods: {
    exportPDF() {
      this.fetchingPDF = true;
      this.$services.invoice.fetchPdfAndOpen(this.modelValue.id)
        .finally(() => {
          this.fetchingPDF = false;
        });
    },
    sendInvoiceToCustomerByEmail() {
      const answer = window.confirm("Etes-vous sûr de vouloir envoyer cette facture par email au client ?");
      if (!answer) {
        return;
      }

      this.sendingInvoiceToCustomerByEmail = true;
      this.$services.invoice.sendToCustomerEmail(this.modelValue.id)
        .finally(() => {
          this.sendingInvoiceToCustomerByEmail = false;
        });
    },
    sendInvoiceToCustomerByMail() {
      const answer = window.confirm("Etes-vous sûr de vouloir envoyer cette facture par courrier postal au client ? Pour envoyer par email, c'est le bouton juste à gauche de celui-ci.");

      if (!answer) {
        return;
      }

      this.sendingInvoiceToCustomerByMail = true;
      this.$services.invoice.sendToCustomerMail(this.modelValue.id)
        .finally(() => {
          this.sendingInvoiceToCustomerByMail = false;
        });
    },
    sendPaymentRequestToCustomer() {
      this.sendingPaymentRequestToCustomerByEmail = true;
      this.$services.invoice.sendPaymentRequestToCustomer(this.modelValue.id)
        .finally(() => {
          this.sendingPaymentRequestToCustomerByEmail = false;
        });
    },
    createCreditNote() {
      this.$router.push({ name: "create-credit-note", query: { invoice: this.modelValue.id } });
    },
    createInvoice() {
      this.$router.push({ name: "funnel.invoice", params: { customerId: this.modelValue.customer.id }, query: { quoteToCopy: this.modelValue.id } });
    },
    clone() {
      this.$router.push({ name: "funnel.invoice", params: { customerId: this.modelValue.customer.id }, query: { invoiceToCopy: this.modelValue.id } });
    },
    createContract() {
      this.$refs.createContractModal.createFromInvoice(this.modelValue);
    },
    onContractCreated(contract) {

      if (!contract.iterations || contract.iterations.length <= 0) {
        throw new Error("New contract should have one iteration at least.");
      }

      const currentIteration = contract.iterations[0];

      this.$services.invoice.patch(
        this.modelValue.id,
        {
          contract_id: currentIteration.id,
        })
        .then((response) => {
          this.$emit(
            "update:modelValue",
            response.data,
          );
        });
    },
    goToAdminPage() {
      window.open(`https://prod.edn.ovh/gdfcm/admin/invoice/invoice/${this.modelValue.id}/change/`, "_blank");
    },
  },
};

</script>
