<template>
  <ObjectViewLayout
    :title="title"
    :loading="object == null"
    withBackButton
    @back="$router.push({ name: 'customer-call-list' })">
    <template #title-icon-left>
      <CustomerIcon />
    </template>

    <template #rightButton>
      <CustomerCallToolButtons v-model="object" />
    </template>

    <template #default>
      <div class="vertical-spaced-container">
        <div class="card">
          <div class="card-header">
            <span class="title">Secrétaire</span>
          </div>
          <div class="card-body">
            <span v-if="object.author">{{ $filters.fullname(object.author) }}</span><span v-else><i>Non indiqué</i></span>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Client</span>
          </div>
          <div class="card-body">
            <span v-if="object.customer">{{ $filters.printCustomer(object.customer) }}</span><span v-else><i>Aucun</i></span>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Informations de contact</span>
          </div>
          <div class="card-body">
            <div class="info-grid-layout">
              <div>
                Société
              </div>
              <div>
                <span v-if="object.company_name">{{ object.company_name }}</span><span v-else><i>Aucun nom renseigné</i></span><br>
                TVA: <span v-if="object.company_vat">{{ object.company_vat }}</span><span v-else><i>Aucune TVA renseignée</i></span>
              </div>
              <div>
                Nom
              </div>
              <div>
                {{ object.last_name }} {{ object.first_name }}
              </div>
              <div>
                Numéro de téléphone
              </div>
              <div>
                <span v-if="object.phone_number">{{ object.phone_number }}</span><span v-else><i>Aucun numéro renseigné</i></span>
              </div>
              <div>
                Adresse e-mail
              </div>
              <div>
                <span v-if="object.email">{{ object.email }}</span><span v-else><i>Aucune adresse e-mail renseignée</i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Adresse</span>
          </div>
          <div class="card-body">
            {{ object.street_number }}
            {{ object.street }}
            {{ object.zipcode }}
            {{ object.city }}
            {{ object.country_code }}
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Activités</span>
          </div>
          <div class="card-body">
            <strong v-if="object.activity">{{ object.activity.name }}</strong><span v-else><i>Aucun</i></span>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Notes</span>
          </div>
          <div class="card-body">
            <p>{{ object.notes }}</p>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Transcription</span>
          </div>
          <div class="card-body">
            <p v-if="object.transcription">
              {{ object.transcription }}
            </p>
            <i v-else class="text-muted">Transcription indisponible</i>
          </div>
        </div>
      </div>
    </template>

    <template #rightBar>
      <div class="vertical-spaced-container">
        <div class="card">
          <div class="card-body">
            <div class="info-grid-layout">
              <div>Créé le</div>
              <div>{{ $filters.dateStringToHumanizedDate(object.created_at) }}</div>
              <div>Modifié le</div>
              <div>{{ $filters.dateStringToHumanizedDate(object.updated_at) }}</div>
              <div>Statut</div>
              <div><span v-if="object.terminated" class="highlighted green">Terminé</span><span v-else class="highlighted">En cours</span></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import CustomerIcon from "@/assets/iconsVue/CustomerIcon.vue";
import CustomerCallToolButtons from "@/components/toolbuttons/CustomerCallToolButtons.vue";

export default {
  name: "CustomerCallObjectView",
  components: {
    ObjectViewLayout,
    CustomerIcon,
    CustomerCallToolButtons,
  },
  data() {
    return {
      object: null,
    };
  },
  computed: {
    title() {
      if (!this.object) {
        return null;
      }

      var title = "Appel du " + this.$filters.dateStringToHumanizedDate(this.object.created_at);

      if (this.object.customer) {
        title = title + " pour " + this.$filters.printCustomer(this.object.customer);
      } else {
        title = title + " pour " + this.$filters.fullname(this.object);
      }

      return title;
    },
  },
  mounted() {
    this.fetchObject();
  },
  methods: {
    fetchObject() {
      this.$services.customerCall.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
    cancel() {
      this.$router.push({ name: "customer-call-list" });
    },
  },
};

</script>

<style lang="scss" scoped>

</style>