<template>
  <LoaderView v-if="schemaIntervention == null" />

  <div v-else>
    <FormField :schema="schemaIntervention.origin" :errors="validationErrors?.origin" style="max-width: 200px;">
      <OriginSelect v-model="internalValue.origin_code" />
    </FormField>
        
    <FormField :schema="schemaIntervention.rdv_date" :errors="validationErrors?.rdv_date">
      <RdvDatePicker
        v-model="internalValue.rdv_date" />
    </FormField>

    <div class="flow-spaced-container">
      <FormField
        title="Le technicien peut arriver à partir de"
        :schema="schemaIntervention.rdv_min_start_time"
        :errors="validationErrors?.rdv_min_start_time">
        <TimeInput
          v-model="internalValue.rdv_min_start_time" />
      </FormField>

      <FormField
        title="Le technicien peut arriver jusqu'à"
        :schema="schemaIntervention.rdv_max_start_time"
        :errors="validationErrors?.rdv_max_start_time">
        <TimeInput
          v-model="internalValue.rdv_max_start_time" />
      </FormField>
    </div>

    <FormField
      v-if="internalValue.custom_duration != null"
      :schema="schemaIntervention.custom_duration"
      :errors="validationErrors?.custom_duration">
      <div class="input-group">
        <input
          v-model="internalValue.custom_duration"
          type="number"
          min="0"
          class="form-control"
          style="width: auto;">
        <div class="input-group-append">
          min
        </div>
      </div>
    </FormField>
        
    <FormField :schema="schemaIntervention.is_emergency" :errors="validationErrors?.is_emergency" hideTitle>
      <template #default>
        <div class="form-check">
          <input
            v-model="internalValue.is_emergency"
            class="form-check-input"
            type="checkbox">
          <label class="form-check-label">
            {{ schemaIntervention.is_emergency.verbose_name }}
          </label>
        </div>
      </template>
    </FormField>
        
    <FormField
      :schema="schemaIntervention.activities"
      :errors="validationErrors?.activities">
      <MultiActivityInput
        :modelValue="internalValue.activity_ids"
        @select="onActivitySelected"
        @unselect="onActivityUnselected" />
    </FormField>

    <FormField
      :schema="schemaIntervention.location"
      :errors="validationErrors?.location">
      <div
        v-for="(location, index) in customer.locations"
        :key="index">
        <div class="form-check">
          <input
            v-model="internalValue.location_id"
            class="form-check-input"
            type="radio"
            :value="location.id"
            @change="if ($event.target.checked) delete internalValue.location;">
          <label class="form-check-label">
            {{ location.street_number }} {{ location.street }} {{ location.bp }}, {{ location.zipcode }} {{ location.city }} {{ location.country_code }}
          </label>
        </div>
      </div>

      <div
        v-if="customLocation">
        <div class="form-check">
          <input
            :checked="internalValue.location != null"
            class="form-check-input"
            type="radio"
            @change="if ($event.target.checked) internalValue.location = customLocation; delete internalValue.location_id;">
          <label class="form-check-label">
            {{ customLocation.street_number }} {{ customLocation.street }} {{ customLocation.bp }}, {{ customLocation.zipcode }} {{ customLocation.city }} {{ customLocation.country_code }}
          </label>
        </div>
      </div>

      <button v-else class="btn-link" @click="editCustomLocation">
        Autre adresse
      </button>
    </FormField>
        
    <FormField
      :schema="schemaIntervention.remarks"
      :errors="validationErrors?.remarks">
      <textarea v-model="internalValue.remarks" class="form-control" />
    </FormField>

    <Modal ref="editLocationModal">
      <LocationForm
        v-if="editedCustomLocation"
        v-model="editedCustomLocation"
        defaultShowCustomForm />

      <template #buttons>
        <button
          class="btn-secondary"
          @click="editedCustomLocation = null; $refs.editLocationModal.hide()">
          Annuler
        </button>

        <button
          class="btn-primary"
          style="margin-left: 5px;"
          :disabled="editedCustomLocation == null || editedCustomLocation.street == null || editedCustomLocation.zipcode == null || editedCustomLocation.city == null || editedCustomLocation.country_code == null"
          @click="onSaveEditedCustomLocation">
          Enregistrer
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import MultiActivityInput from "@/components/inputs/MultiActivityInput.vue";
import OriginSelect from "@/components/selects/OriginSelect.vue";
import RdvDatePicker from "@/components/inputs/RdvDatePicker.vue";
import TimeInput from "@/components/baseComponents/TimeInput.vue";
import Modal from "@/components/modals/Modal.vue";
import LocationForm from "@/components/forms/LocationForm.vue";
import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "CreateInterventionForm",
  components: {
    FormField,
    MultiActivityInput,
    OriginSelect,
    RdvDatePicker,
    TimeInput,
    Modal,
    LocationForm,
    LoaderView,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
      validator: (value) => {
        if (!value.locations || value.locations.length <= 0) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain at least one location");
        }

        return true;
      },
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      // States
      initializing: false,
      submitting: false,

      // Schemas
      schemaIntervention: null,

      // Intervention to create
      customLocation: null,
      editedCustomLocation: null,

      // ValidationErrors
      validationErrors: null,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
  },
  async mounted() {
    this.fetchSchema();
  },
  methods: {

    // Fetch schemas

    fetchSchema() {
      return this.$services.intervention.schema()
        .then((response) => {
          this.schemaIntervention = response.data;
        });
    },

    // Events

    onActivitySelected(activity) {
      if (this.internalValue.activity_ids == null) {
        this.internalValue.activity_ids = [];
      }

      const index = this.internalValue.activity_ids.findIndex((alreadySelectedActivityId) => {
        return alreadySelectedActivityId == activity.id;
      });

      if (index == -1) {
        this.internalValue.activity_ids.push(activity.id);
      }
    },

    onActivityUnselected(activity) {
      if (this.internalValue.activity_ids == null) {
        this.internalValue.activity_ids = [];
      }

      const index = this.internalValue.activity_ids.findIndex((alreadySelectedActivityId) => {
        return alreadySelectedActivityId == activity.id;
      });

      if (index != -1) {
        this.internalValue.activity_ids.splice(index, 1);
      }
    },

    editCustomLocation() {
      if (this.editedCustomLocation == null) {
        this.editedCustomLocation = {};
      }
      this.$refs.editLocationModal.show();
    },

    onSaveEditedCustomLocation() {
      this.customLocation = this.editedCustomLocation;
      this.editedCustomLocation = null;
      this.internalValue.location = this.customLocation;
      delete this.internalValue.location_id;
      this.$refs.editLocationModal.hide();
    },
  },
};
</script>
