import { defineStore } from "pinia";

export interface State {
  activities: Array<any>;
}

export const useActivitiesStore = defineStore("activities",{
  state: (): State => ({
    activities: [],
  }),
  getters: {
    getActivities: state => {
      return state.activities;
    },
    getActivitiesForCustomerCallCreationForm: state => {
      if (!state.activities) {
        return null;
      } else {
        return state.activities.filter(activity => activity.show_in_customer_call_creation_form == true);
      }
    },
    getActivityById: state => (id: number) => {
      if (!state.activities) {
        return null;
      }
      return state.activities.find(activity => activity.id == id);
    },
  },
  actions: {
    setActivities(activities: Array<any>) {
      this.activities = activities;
    },
  },
});
