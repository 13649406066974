import axios from "axios";
import { useMeStore } from "@/stores/me";
import { notifyError } from "@/notifications";



const baseURL = import.meta.env.VITE_VUE_APP_BACKEND_URL;

if (!baseURL) {
  throw new Error("Backend URL not defined");
}

console.log("Backend: %s", baseURL);

const api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

// Stats

function stats() {
  console.warn("api.ts: stats() is deprecated");
  return "/stats/";
}

function interventionStats() {
  console.warn("api.ts: interventionStats() is deprecated");
  return "/stats/interventions/";
}


function bonus(year: number, month: number, technicianId = null) {
  console.warn("api.ts: bonus() is deprecated");
  if (technicianId) {
    return `/bonus/?year=${year}&month=${month}&technician=${technicianId}`;
  } else {
    return `/bonus/?year=${year}&month=${month}`;
  }
}

// Interventions


function makeAppointmentForIntervention(id: number) {
  console.warn("api.ts: makeAppointmentForIntervention() is deprecated");
  return `/interventions/${id}/make_appointment/`;
}

// Planning staff

function planningStaff(year: number, month: number) {
  console.warn("api.ts: planningStaff() is deprecated");
  return `/planning-staff/?year=${year}&month=${month}`;
}

function leaveTypes() {
  console.warn("api.ts: leaveTypes() is deprecated");
  return "/leave-types/";
}

const uri = {
  stats,
  interventionStats,

  bonus,

  makeAppointmentForIntervention,  

  planningStaff,
  leaveTypes,
};


function setInterceptors() {
  api.interceptors.response.use(
    response => response,
    (error) => {

      const meStore = useMeStore();

      if (error.response) {
        if (error.response.status === 401) {

          console.log("Unautorised response intercepted from url %s", error.config.url);

          // Received unauthorised response after trying to refresh access token
          // This means the refresh token used has expired, so we logout.
          if (error.config.url.includes("auth/token/refresh/")) {
            console.log("Failed to refresh access token. Logout.");
            meStore.logout();
            return new Promise((_resolve, reject) => {
              reject();
            });
          }

          // Received unauthorised response after trying to login.
          // Propagate the error
          else if (error.config.url.includes("auth/token/")) {
            console.log("Login failed.");
            return new Promise((_resolve, reject) => {
              reject(error);
            });
          }

          // For any other request that retured an unautorised response, 
          // It probably means that the access token has expired.
          // Try to refresh access token.
          console.log("Refresh access token");
          
          return  meStore.refreshAccessToken()
            .then((newAccessToken: string) => {
              // Access token has been successfully refreshed, resend the request that failed but with new access token
              console.log("Access token refreshed as ", newAccessToken);
              
              // Fetch initial request that failed
              const config = error.config;
              
              // Replace access token with new access token in this request
              config.headers["Authorization"] = `Bearer ${newAccessToken}`;
              
              return new Promise((resolve, reject) => {
                axios
                  .request(config)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    if (error.response && error.response.status === 401) {
                      console.error("Unauthorized response after token refreshed :", error.response.data);
                    }

                    reject(error);
                  });
              });
            });

        } else if (error.response.status === 403) {
          notifyError("Vous n'avez pas les droits pour effectuer cette requète");
        } else if (error.response.status === 500) {
          notifyError("Il y a eu une erreur dans le serveur");
        } else if (error.response.status === 400) {
          notifyError("Un ou plusieurs champs n'ont pas été correctements remplis");
        }
      } else {
        notifyError("Serveur injoignable");
      }

      return Promise.reject(error);
    });
}


function encode(args: object) : string {
  const str = [];

  function isEmpty(value: any): boolean {
    if (value === null) {
      return true;
    }
  
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
  
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
  
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
  
    return false;
  }

  for (const [key, value] of Object.entries(args)) {
    if (!isEmpty(value)) {
      str.push(`${key}=${encodeURIComponent(value)}`);
    }
  }

  return str.join("&");
}

export { api, uri, setInterceptors, encode };
