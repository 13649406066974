<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Client</span>
    </div>

    <div class="card-body">
      <!-- Select existing customer-->

      <div v-if="selectExistingCustomer">
        <div class="form-group" style="max-width: 300px;">
          <SelectObject
            type="customer"
            :selectedValue="selectedCustomer ? $filters.printCustomer(selectedCustomer) : null"
            @select="onExistingCustomerSelect"
            @unselect="onExistingCustomerSelect(null)" />
        </div>
        <div>
          <button class="btn-link" @click="selectExistingCustomer = false">
            Créer un nouveau client
          </button>
        </div>
      </div>

      <!-- Create new customer-->

      <div v-else>
        <div class="form-group">
          <button class="btn-link" @click="selectExistingCustomer = true">
            Sélectionner un client existant
          </button>
        </div>

        <NewCustomerForm
          @change="newCustomer = $event" />
      </div>
    </div>

    <div class="card-footer justify-right">
      <button
        :disabled="!canSubmit || submitting"
        :class="{ 'loading': submitting }"
        class="btn btn-primary"
        @click="submit">
        Suivant
      </button>
    </div>

    <ConfirmModal
      ref="confirmUseConflictingCustomer"
      @confirm="confirmUseConflictingCustomer" />
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import NewCustomerForm from "@/components/forms/NewCustomerForm.vue";
import { notifyError } from "@/notifications";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  name: "CustomerCreationStepCard",
  components: {
    SelectObject,
    NewCustomerForm,
    ConfirmModal,
  },
  data() {
    return {
      // States
      submitting: false,

      // Selected customer
      selectedCustomer: null,

      // Customer to create
      newCustomer: null,

      // Modes
      selectExistingCustomer: true,
    };
  },
  computed: {

    // Params

    mode() {
      return this.$route.params.mode;
    },

    interventionToCopyId() {
      return this.$route.query.interventionToCopyId;
    },

    // Validation

    canSubmit() {
      if (this.selectExistingCustomer) {
        return this.selectedCustomer != null;

      } else {
        if (this.newCustomer == null || this.newCustomer.contacts.length <= 0) {
          return false;

        } else {
          for (const contact of this.newCustomer.contacts) {
            if (contact.last_name == null || contact.language == null) {
              return false;
            }
          }
        }

        return this.newCustomer.billing_person_last_name != null && this.newCustomer.billing_street != null && this.newCustomer.billing_zipcode != null && this.newCustomer.billing_city != null && this.newCustomer.billing_country_code != null;
      }
    },
    
  },

  mounted() {
    if (this.interventionToCopyId) {
      this.$services.intervention.retrieve(this.interventionToCopyId)
        .then((response) => {
          this.selectedCustomer = response.data.customer;
        });
    }
  },
  methods: {
    onExistingCustomerSelect(customer) {
      this.selectedCustomer = customer;
      this.selectExistingCustomer = true;
    },

    async submit() {
      if (!this.selectExistingCustomer) {
        const customer = await this.createCustomer();

        if (customer != null) {
          this.$router.push({
            name: "funnel.intervention",
            params: {
              customerId: customer.id,
              mode: this.mode,
            },
            query: this.$route.query,
          });
        }

        
      } else {
        this.$router.push({
          name: "funnel.intervention",
          params: {
            customerId: this.selectedCustomer.id,
            mode: this.mode,
            
          },
          query: this.$route.query,
        });
      }
    },

    createCustomer() {
      return new Promise((resolve, reject) => {
        this.submitting = true;
        this.$services.customer.create(this.newCustomer)
          .then((response) => {
            this.selectedCustomer = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            if (error && error.response && error.response.status === 409) {
              const conflictingCustomer = error.response.data.conflicting_customer;
              const conflictingField = error.response.data.conflicting_field;

              this.$refs.confirmUseConflictingCustomer.show(
                `Le client ${this.$filters.printCustomer(conflictingCustomer)} existe déjà avec ${conflictingField === "email" ? "cette adresse email" : conflictingField === "phone_number" ? "ce numéro de téléphone" : conflictingField === "vat_number" ? "ce numéro de TVA" : conflictingField === "location" ? "cette adresse" : "champ inconnu"}.\nVoulez-vous utiliser ce client à la place ?`,
                "Oui",
                "Non, modifier les infos",
                conflictingCustomer,
              );
              resolve(null);
            } else {
              notifyError("Une erreur est survenue lors de la création du client.");
              reject(error);
            }
            
          })
          .finally(() => {
            this.submitting = false;
          });
      });
    },

    confirmUseConflictingCustomer(conflictingCustomer) {
      this.onExistingCustomerSelect(conflictingCustomer);
      this.$refs.confirmUseConflictingCustomer.hide();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>