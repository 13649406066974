<template>
  <ObjectViewLayout
    title="Enregistrer un appel client"
    withBackButton
    :loading="callSchema == null"
    rightBarWidth="3"
    @back="$router.push({ name: 'customer-call-list' })">
    <div class="vertical-spaced-container">
      <div class="flow-spaced-container fill">
        <FormField
          title="Origine">
          <OriginInput v-model="origin" @change="onOriginChange" />
        </FormField>

        <FormField
          :schema="callSchema.phone_number">
          <PhoneNumber v-model="call.phone_number" :disabled="noPhoneNumber" style="min-width: 300px;" @blur="checkPhoneNumber" />
          <small
            v-if="!call.phone_number && !noPhoneNumber"
            class="form-text text-red blinking-text">
            Indiquez le numéro de téléphone pour enregistrer l'appel
          </small>
          <small
            v-if="existingCustomerWithSamePhoneNumber"
            class="form-text text-green">
            <span style="margin-right: 10px;">
              Client correspondant trouvé : {{ existingCustomerWithSamePhoneNumber.contact.first_name }} {{ existingCustomerWithSamePhoneNumber.contact.last_name }}
            </span>
            <button class="btn-link" style="margin-right: 10px;" @click="openExistingCustomerPageInNewTab">
              Voir le client
            </button>
            <button class="btn-link" @click="loadInfoFromExistingCustomer">
              Charger les infos du client
            </button>
          </small>
          <div
            v-if="!call.phone_number"
            class="form-check"
            style="margin-top: 2px;">
            <input v-model="noPhoneNumber" class="form-check-input" type="checkbox">
            <label class="form-check-label">Pas de numéro</label>
          </div>
        </FormField>
      </div>
      
      <div class="flow-spaced-container fill">
        <div class="card">
          <div class="card-header">
            <span class="title">Informations</span>
          </div>

          <div class="card-body">
            <CustomerCallReason
              v-for="(reason, index) in reasons"
              :key="index"
              :reason="reason" />
          </div>
        </div>
          

        <div class="card">
          <div class="card-header">
            <span class="title">Activités</span>
          </div>

          <div class="card-body">
            <FormField
              hideTitle
              :schema="callSchema.activities"
              class="form-group col-sm">
              <template #default>
                <ActivityForm v-model="call.activity_id" @change="onActivityChange" />
              </template>
            </FormField>
          </div>
        </div>
      </div>

      <div class="flow-spaced-container fill">
        <div class="card">
          <div class="card-header">
            <span class="title">Informations de contact</span>
          </div>
          <div class="card-body">
            <div class="row">
              <FormField
                :schema="callSchema.company_name"
                style="max-width: 300px;"
                class="col-sm">
                <template #default>
                  <input v-model="call.company_name" type="text" class="form-control">
                </template>
              </FormField>

              <FormField
                :schema="callSchema.company_vat"
                class="col-sm"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.company_vat" type="text" class="form-control">
                </template>
              </FormField>
            </div>
  
            <div class="row">
              <FormField
                :schema="callSchema.first_name"
                class="col-sm"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.first_name" type="text" class="form-control">
                </template>
              </FormField>

              <FormField
                :schema="callSchema.last_name"
                class="col-sm"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.last_name" type="text" class="form-control">
                </template>
              </FormField>
            </div>
  
            <div class="row">
              <FormField
                :schema="callSchema.email"
                class="col-sm"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.email" type="text" class="form-control">
                </template>
              </FormField>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Adresse</span>
          </div>

          <div class="card-body">
            <div class="form-group">
              <VueGoogleAutocomplete
                id="address-search"
                :country="countryCodes"
                type="text"
                placeholder="Recherchez une adresse"
                class="form-control"
                @placechanged="onAddressSuggestion" />
            </div>

            <div class="row">
              <FormField
                class="col-sm"
                :schema="callSchema.street_number"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.street_number" type="text" class="form-control">
                </template>
              </FormField>

              <FormField
                class="col-sm"
                :schema="callSchema.street"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.street" type="text" class="form-control">
                </template>
              </FormField>
            </div>

            <div class="row">
              <FormField
                class="col-sm"
                :schema="callSchema.zipcode"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.zipcode" type="text" class="form-control">
                </template>
              </FormField>

              <FormField
                class="col-sm"
                :schema="callSchema.city"
                style="max-width: 300px;">
                <template #default>
                  <input v-model="call.city" type="text" class="form-control">
                </template>
              </FormField>

              <FormField
                class="col-sm"
                :schema="callSchema.country_code"
                style="max-width: 300px;">
                <template #default>
                  <CountrySelect v-model="call.country_code" class="form-select" />
                </template>
              </FormField>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <span class="title">Notes</span>
        </div>

        <div class="card-body">
          <div class="flow-spaced-container fill">
            <div style="margin-bottom: 10px;">
              <div v-html="questions" />
            </div>
            <FormField
              style="height: 100%; margin: 0;"
              :schema="callSchema.notes"
              hideTitle>
              <template #default>
                <textarea v-model="notes" class="form-control" style="height: 100%;" :disabled="call.activity_id == null" />
              </template>
            </FormField>
          </div>
        </div>
      </div>

      <div v-if="existingCallsWithSamePhoneNumber.length > 0" class="card">
        <div class="card-header">
          <span class="title">Ancien appels</span>
        </div>

        <div class="card-body">
          <div v-for="oldCall in existingCallsWithSamePhoneNumber" :key="oldCall.id">
            <strong>{{ $filters.dateStringToHumanizedDateTime(oldCall.created_at) }}</strong>
            {{ oldCall.notes }}
          </div>
        </div>
      </div>

      <div class="flow-spaced-container fill">
        <div class="card">
          <div class="card-header">
            <span class="title">Produits</span>
          </div>

          <div class="card-body">
            <CustomerCallProductForm
              v-if="origin && call.activity_id != null"
              v-model:products="defaultProductLines"
              :origin="origin" />
            <i v-else>Sélectionnez d'abord une origine et une activité</i>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Durée prévue de l'intervention</span>
          </div>

          <div class="card-body">
            <span v-if="call.activity_id != null">{{ call.activity_id.average_duration }} minutes</span>
            <i v-else>Sélectionnez d'abord une activité</i>
          </div>
        </div>
      </div>

      <div class="card buttons-container">
        <div class="card-body">
          <div class="horizontal-spaced-container">
            <div v-if="savingCall">
              <Loader />
              Sauvegarde automatique...
            </div>

            <button
              class="btn-primary"
              :class="{ 'loading': endingCall }"
              :disabled="savingCall || endingCall || submittingIntervention || !canSaveCall"
              @click="endCall">
              Terminer l'appel
            </button>

            <button
              class="btn-primary"
              :class="{ 'loading': submittingIntervention }"
              :disabled="savingCall || endingCall || submittingIntervention || !canCreateIntervention"
              @click="createIntervention">
              Créer une demande d'intervention
            </button>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <span class="title">Créneaux disponibles</span>
        </div>

        <div v-if="activity == null || call.zipcode == null || call.country_code == null" class="card-body">
          <i>Commencez par sélectionner une activité,  un code postal et un pays</i>
        </div>

        <div v-else class="card-body">
          <FreeSlots
            :activityIds="[activity.id]"
            :zipcode="call.zipcode"
            :countryCode="call.country_code" />
        </div>
      </div>
    </div>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormField from "@/components/forms/FormField.vue";
import ActivityForm from "@/components/forms/ActivityForm.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PhoneNumber from "@/components/vueComponents/input/PhoneNumber.vue";
import CustomerCallProductForm from "@/components/forms/CustomerCallProductForm.vue";
import OriginInput from "@/components/inputs/OriginInput.vue";
import CountrySelect from "@/components/inputs/CountrySelect.vue";
import CustomerCallReason from "@/components/others/CustomerCallReason.vue";
import Loader from "@/components/Loader.vue";
import FreeSlots from "@/views/interventions/FreeSlots.vue";

import { useCurrentCustomerCallStore } from "@/stores/currentCustomerCall";
import { useActivitiesStore } from "@/stores/activities";
import { favoriteCountries } from "@/countries";

export default {
  name: "CustomerCallObjectCreationView",
  components: {
    ObjectViewLayout,
    FormField,
    ActivityForm,
    VueGoogleAutocomplete,
    PhoneNumber,
    CustomerCallProductForm,
    OriginInput,
    CountrySelect,
    CustomerCallReason,
    Loader,
    FreeSlots,
  },
  beforeRouteLeave() {
    if (this.canSaveCall) {
      this.saveCall();
    }
  },
  data() {
    return {
      savingCall: false,
      endingCall: false,
      submittingIntervention: false,
      callSchema: null,
      existingCustomerWithSamePhoneNumber: null,
      existingCallsWithSamePhoneNumber: [],
      origin: {
        code: "EDN-BE-FR",
      },
      defaultProductLines: [],
      noPhoneNumber: false,
      store: useCurrentCustomerCallStore(),
      activitiesStore: useActivitiesStore(),
    };
  },
  computed: {
    reasons() {
      return this.store.getPossibleReasons;
    },
    activity() {
      if (this.call.activity_id) {
        return this.activitiesStore.getActivityById(this.call.activity_id);
      } else {
        return null;
      }
    },
    call: {
      get() {
        return this.store.getCall;
      },
      set(value) {
        this.store.setCall(value);
      },
    },
    notes: {
      get() {
        if (this.activity == null) {
          return "Sélectionnez d'abord une activité...";
        } else {
          return this.store.getNotes;
        }
      },
      set(value) {
        this.store.setNotes(value);
      },
    },
    canCreateIntervention() {
      return this.call.phone_number != null && this.call.phone_number.length > 0 && this.call.last_name != null && this.call.street != null && this.call.city != null && this.call.zipcode != null && this.call.country_code != null && this.call.activity_id != null;
    },
    canSaveCall() {
      return this.noPhoneNumber || (this.call.phone_number != null && this.call.phone_number.length > 0);
    },
    countryCodes() {
      return Object.keys(favoriteCountries);
    },
    questions() {
      if (this.activity == null) {
        return null;
      } else {
        return this.activity.questions.replaceAll("\n", "<br>");
      }
    },
  },
  watch: {
    call: {
      handler(newVal) {
        this.store.setCall(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.store.load();
    this.store.fetchPossibleReasons();
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.customerCall.schema()
        .then((response) => {
          this.callSchema = response.data;
        });
    },
    onAddressSuggestion(addressData) {
      this.call.street_number = addressData.street_number;
      this.call.street = addressData.route;
      this.call.zipcode = addressData.postal_code;
      this.call.city = addressData.locality;
      this.call.country_code = this.formatCountry(addressData.country);
    },
    formatCountry(country) {
      switch (country.toLowerCase()) {
      case "belgique":
        return "be";
      case "belgium":
        return "belgique";
      case "luxembourg":
        return "lu";
      case "france":
        return "fr";
      case "pays-bas":
        return "nl";
      }
      throw new Error("Google Autocomplete returned unknown country: " + country);
    },
    onOriginChange(newOrigin) {
      this.loadDefaultProductLinesFromOriginAndActivities(newOrigin, this.activity);
    },
    onActivityChange(newActivityId) {
      const newActivity = this.activitiesStore.getActivityById(newActivityId);
      
      this.loadDefaultProductLinesFromOriginAndActivities(this.origin, newActivity);
    },
    onReasonSelected(newReason) {
      if (newReason.questions != null && newReason.questions.length > 0) {
        this.notes = `${newReason.questions}\n\n${this.notes ? this.notes : ""}`;
      }
    },
    loadDefaultProductLinesFromOriginAndActivities(origin, activity) {
      if (origin == null) {
        return;
      }

      if (activity == null) {
        return;
      }

      this.defaultProductLines = this.defaultProductLines.concat(activity.default_product_lines.filter((line) => {
        return line.origin == origin.code;
      }));
    },
    checkPhoneNumber() {
      if (this.call.phone_number == null || this.call.phone_number.length < 5) {
        return;
      }
      
      this.$services.customer.list({phone_number: this.call.phone_number})
        .then((response) => {
          if (response.data.nb_results > 0) {
            this.existingCustomerWithSamePhoneNumber = response.data.results[0];
          } else {
            this.existingCustomerWithSamePhoneNumber = null;
          }
        });

      this.$services.customerCall.list({phone_number: this.call.phone_number})
        .then((response) => {
          this.existingCallsWithSamePhoneNumber = response.data.results;
        });
    },
    openExistingCustomerPageInNewTab() {
      const routeData = this.$router.resolve({name: "customer", params: {id: this.existingCustomerWithSamePhoneNumber.id}});
      window.open(routeData.href, "_blank");
    },
    loadInfoFromExistingCustomer() {
      this.call.customer_id = this.existingCustomerWithSamePhoneNumber.id;
      this.call.first_name = this.existingCustomerWithSamePhoneNumber.contact.first_name;
      this.call.last_name = this.existingCustomerWithSamePhoneNumber.contact.last_name;
      this.call.email = this.existingCustomerWithSamePhoneNumber.contact.email;
      this.call.company_name = this.existingCustomerWithSamePhoneNumber.contact.company_name;
      this.call.company_vat = this.existingCustomerWithSamePhoneNumber.contact.company_vat;
      this.call.street_number = this.existingCustomerWithSamePhoneNumber.address.street_number;
      this.call.street = this.existingCustomerWithSamePhoneNumber.address.street;
      this.call.zipcode = this.existingCustomerWithSamePhoneNumber.address.zipcode;
      this.call.city = this.existingCustomerWithSamePhoneNumber.address.city;
      this.call.country_code = this.existingCustomerWithSamePhoneNumber.address.country_code;
    },
    saveCall() {
      this.savingCall = true;

      this.store.save()
        .finally(() => {
          this.savingCall = false;
        });
    },
    endCall() {
      this.endingCall = true;

      this.store.terminate()
        .then((response) => {
          this.$router.push({ name: "customer-call", params: { id: response.data.id } });
        })
        .finally(() => {
          this.endingCall = false;
        });
    },
    createIntervention() {
      this.submittingIntervention = true;

      this.store.convert()
        .then((response) => {
          this.$router.push({ name: "intervention", params: { id: response.data.intervention } });      
        }).finally(() => {
          this.submittingIntervention = false;
        });
    },
    cancel() {
      this.$router.push({ name: "customer-call-list" });
    },
  },
};
</script>



<style scoped lang="scss">

.buttons-container {
  position: sticky;
  bottom: 0px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px 0px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

</style>