<template>
  <nav aria-label="Progress">
    <ol role="list" class="divide-y divide-gray-400 rounded-md border border-gray-400 md:flex md:divide-y-0">
      <li v-for="(step, stepIdx) in steps" :key="step.name" class="relative md:flex md:flex-1">
        <div v-if="step.status === 'complete'" class="step flex w-full items-center" @click="goto(step.id)">
          <span class="flex items-center px-6 py-4 text-sm font-medium">
            <span class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full background-primary step-icon">
              <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <span class="ml-4 text-sm font-medium text-gray-900">{{ step.name }}</span>
          </span>
        </div>
        <div v-else-if="step.status === 'current'" class="flex items-center px-6 py-4 text-sm font-medium" aria-current="step" @click="goto(step.id)">
          <span class="ml-4 text-sm font-medium text-primary">{{ step.name }}</span>
        </div>
        <div v-else class="flex items-center" @click="goto(step.id)">
          <span class="flex items-center px-6 py-4 text-sm font-medium">
            <span class="ml-4 text-sm font-medium text-gray-600">{{ step.name }}</span>
          </span>
        </div>
        <template v-if="stepIdx !== steps.length - 1">
          <!-- Arrow separator for lg screens and up -->
          <div class="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg class="h-full w-full text-gray-400" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
            </svg>
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>

import { CheckIcon } from "@heroicons/vue/24/solid";

export default {
  name: "Steps",
  components: {
    CheckIcon,
  },
  props: {
    steps: {
      type: Array,
      required: false,
      default: function () {
        return [
          { id: "01", name: "Job details", href: "#", status: "complete" },
          { id: "02", name: "Application form", href: "#", status: "current" },
          { id: "03", name: "Preview", href: "#", status: "upcoming" },
        ];
      },
    },
  },
  emits: ["goto"],
  methods: {
    goto(id) {
      this.$emit("goto", id);
    },
  },
};
</script>


<style scoped lang="scss">

  .step {
    cursor: pointer;

    &:hover {
      .step-icon {
        filter: brightness(85%);
      }
    }
  }

  .background-primary {
    background-color: $primary;
  }

  .text-primary {
    color: $primary;
  }

  .border-primary {
    border-color: $primary;
  }

</style>