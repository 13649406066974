<template>
  <div 
    class="slot">
    <div
      v-if="slotProp.previous_planning_event"
      class="slot-previous-event"
      :class="{ 'visible': extended }">
      <div style="margin: 10px;">
        <p>
          <strong>Rendez-vous précédent</strong>
        </p>

        <p v-if="slotProp.previous_planning_event.intervention">
          <button
            class="btn-link"
            @click="openInterventionInNewTab(slotProp.previous_planning_event.intervention.id)">
            Intervention pour {{ $filters.fullname(slotProp.previous_planning_event.intervention.contact) }} <i class="bi bi-box-arrow-up-right" />
          </button>
        </p>

        <p v-else-if="slotProp.previous_planning_event.event_type == 'personnal_event'">
          Evènement perso
        </p>

        <p v-else-if="slotProp.previous_planning_event.event_type == 'leave'">
          Congé
        </p>

        <p>{{ $filters.capitalize(previousPlanningEventStartAndEndDate) }}</p>
        
        <p style="margin-bottom: 0;">
          Lieu:
          <span
            v-if="slotProp.previous_planning_event.location"
            style="margin-left: 5px;">
            {{ slotProp.previous_planning_event.location }}
          </span>

          <i 
            v-else
            style="margin-left: 5px;">Inconnu</i>

          <span
            v-if="slotProp.previous_planning_event.latitude && slotProp.previous_planning_event.longitude"
            style="margin-left: 5px;">
            ({{ slotProp.previous_planning_event.latitude }}, {{ slotProp.previous_planning_event.longitude }})
          </span>

          <a
            v-if="slotProp.previous_planning_event.latitude && slotProp.previous_planning_event.longitude"
            style="margin-left: 5px;"
            :href="`https://www.google.com/maps/search/?api=1&query=${ slotProp.previous_planning_event.latitude },${ slotProp.previous_planning_event.longitude }`"
            target="_blank"
            class="btn-link">
            <i class="bi bi-geo-alt" />
          </a>
        </p>
      </div>
    </div>

    <div class="slot-body">
      <div style="flex-grow: 1;">
        <div style="cursor: pointer;" @click="toggle">
          <div style="display: flex; width: 100%; justify-content: space-between;">
            <div>
              <strong>
                Le {{ $filters.dateStringToHumanizedDate(slotProp.intervention_start_datetime ? slotProp.intervention_start_datetime : slotProp.intervention_end_datetime) }}
              </strong>
              <span v-if="slotProp.intervention_start_datetime && slotProp.intervention_end_datetime"> entre </span>
              <span v-else-if="slotProp.intervention_start_datetime"> a partir de </span>
              <span v-else-if="slotProp.intervention_end_datetime"> jusqu'à </span>
              <span v-if="slotProp.intervention_start_datetime">{{ $filters.dateStringToHumanizedTime(slotProp.intervention_start_datetime, true) }}</span>
              <span v-if="slotProp.intervention_start_datetime && slotProp.intervention_end_datetime"> et </span>
              <span v-if="slotProp.intervention_end_datetime">{{ $filters.dateStringToHumanizedTime(slotProp.intervention_end_datetime, true) }}</span>
            </div>

            <div>
              + {{ slotProp.additional_flying_distance }}km vol d'oiseau
            </div>
          </div>

          <p>
            Pour <strong>{{ $filters.fullname(slotProp.user) }}</strong>
          </p>

          <p>
            {{ $filters.fullname(slotProp.user) }} <span v-if="slotProp.previous_planning_event">est à {{ slotProp.previous_planning_event.location }} jusqu'à {{ slotProp.previous_planning_event.end_time.substring(0, 5) }}</span> <span v-if="slotProp.previous_planning_event && slotProp.next_planning_event">puis</span> <span v-if="slotProp.next_planning_event">doit se rendre à {{ slotProp.next_planning_event.location }} pour {{ slotProp.next_planning_event.start_time.substring(0, 5) }}</span>
          </p>
          
          <p>
            <a
              v-if="slotProp.previous_planning_event && slotProp.next_planning_event"
              :href="`https://www.google.com/maps/dir/${ slotProp.previous_planning_event.location }/${interventionAddress}/${ slotProp.next_planning_event.location }`"
              target="_blank"
              class="btn-link">
              <i class="bi bi-map" />
              Voir le trajet sur Google Maps
            </a>
            <a
              v-else-if="slotProp.previous_planning_event"
              :href="`https://www.google.com/maps/dir/${ slotProp.previous_planning_event.location }/${interventionAddress}`"
              target="_blank"
              class="btn-link">
              <i class="bi bi-map" />
              Voir le trajet sur Google Maps
            </a>
            <a
              v-else-if="slotProp.next_planning_event"
              :href="`https://www.google.com/maps/dir/${interventionAddress}/${ slotProp.next_planning_event.location }`"
              target="_blank"
              class="btn-link">
              <i class="bi bi-map" />
              Voir le trajet sur Google Maps
            </a>
          </p>
        </div>

        <div class="horizontal-spaced-container align-right">
          <button
            class="btn-link"
            @click="$refs.detailsModal.show()">
            Debug
          </button>

          <button
            class="btn-secondary"
            @click="$refs.selectSlotModal.show(slotProp, intervention)">
            Sélectionner
          </button>          
        </div>

        <Modal ref="detailsModal" width="medium">
          <div
            v-if="slotProp.previous_planning_event">
            <p>Heure de fin du précédent rendez-vous : {{ slotProp.previous_planning_event.end_time }}</p>
            <p>Temps de trajet estimé depuis le précédent rendez-vous : {{ durationTripFromPreviousPlanningEvent != null ? $filters.secondsToHumanDuration(durationTripFromPreviousPlanningEvent) : "Inconnu" }}</p>
            <p>Heure conseillée pour programmer l'intervention : {{ slotProp.previous_planning_event.end_time }} + {{ durationTripFromPreviousPlanningEvent != null ? $filters.secondsToHumanDuration(durationTripFromPreviousPlanningEvent) : "Inconnu" }} = {{ advisedStartTime }}</p>
            <p>Durée estimée de l'intervention : {{ interventionDuration }}</p>
            <p>Heure conseillée de fin d'intervention : {{ advisedStartTime }} + {{ interventionDuration }} = {{ advisedEndTime }}</p>
            <p v-if="slotProp.next_planning_event">
              Heure de début du prochain rendez-vous : {{ slotProp.next_planning_event.start_time }}
            </p>
            <p v-if="slotProp.next_planning_event">
              Temps de trajet estimé jusqu'au prochain rendez-vous : {{ durationTripToNextPlanningEvent != null ? $filters.secondsToHumanDuration(durationTripToNextPlanningEvent) : "Inconnu" }}
            </p>
            <p v-if="slotProp.next_planning_event">
              Heure la plus tardive pour quitter l'intervention et arriver au rendez-vous suivant à l'heure : TODO
            </p>
            <p v-if="slotProp.next_planning_event">
              Temps libre entre l'intervention et le rendez-vous suivant : TODO
            </p>
          </div>

          <div
            v-else-if="slotProp.next_planning_event">
            <p>Heure de début du rendez-vous suivant : {{ slotProp.next_planning_event.start_time }}</p>
            <p>Temps de trajet estimé jusqu'au endez-vous suivant : {{ durationTripToNextPlanningEvent != null ? $filters.secondsToHumanDuration(durationTripToNextPlanningEvent) : "Inconnu" }}</p>
            <p>Heure conseillée de fin d'intervention : {{ slotProp.next_planning_event.start_time }} - {{ durationTripToNextPlanningEvent != null ? $filters.secondsToHumanDuration(durationTripToNextPlanningEvent) : "Inconnu" }} = {{ advisedEndTime }}</p>
            <p>Durée estimée de l'intervention : {{ interventionDuration }}</p>
            <p>Heure conseillée de début d'intervention : {{ advisedEndTime }} - {{ interventionDuration }} = {{ advisedStartTime }}</p>
          </div>
        </Modal>
      </div>

      <div
        v-if="extendable"
        style="display: flex; justify-content: flex-end; align-items: center; font-size: 2rem; cursor: pointer;"
        class="text-muted"
        @click="toggle">
        <i class="extend-icon bi bi-chevron-compact-down" :class="{ 'upside-down': extended }" />
      </div>
    </div>

    <div v-if="slotProp.next_planning_event" class="slot-next-event" :class="{ 'visible': extended }">
      <div style="margin: 10px;">
        <p>
          <strong>Rendez-vous suivant</strong>
        </p>

        <p v-if="slotProp.next_planning_event.intervention">
          <button
            class="btn-link"
            @click="openInterventionInNewTab(slotProp.next_planning_event.intervention.id)">
            Intervention pour {{ $filters.fullname(slotProp.next_planning_event.intervention.contact) }} <i class="bi bi-box-arrow-up-right" />
          </button>
        </p>

        <p v-else-if="slotProp.next_planning_event.event_type == 'personnal_event'">
          Evènement perso
        </p>

        <p v-else-if="slotProp.next_planning_event.event_type == 'leave'">
          Congé
        </p>

        <p>{{ $filters.capitalize(nextPlanningEventStartAndEndDate) }}</p>

        <p style="margin-bottom: 0;">
          Lieu:
          <span
            v-if="slotProp.next_planning_event.location"
            style="margin-left: 5px;">
            {{ slotProp.next_planning_event.location }}
          </span>

          <i
            v-else>Inconnu</i>

          <span
            v-if="slotProp.next_planning_event.latitude && slotProp.next_planning_event.longitude"
            style="margin-left: 5px;">
            ({{ slotProp.next_planning_event.latitude }}, {{ slotProp.next_planning_event.longitude }})
          </span>

          <a
            v-if="slotProp.next_planning_event.latitude && slotProp.next_planning_event.longitude"
            style="margin-left: 5px;"
            :href="`https://www.google.com/maps/search/?api=1&query=${ slotProp.next_planning_event.latitude },${ slotProp.next_planning_event.longitude }`"
            target="_blank"
            class="btn-link">
            <i class="bi bi-geo-alt" />
          </a>
        </p>
      </div>
    </div>

    <SelectSlotModal ref="selectSlotModal" />
  </div>
</template>

<script>


import Modal from "@/components/modals/Modal.vue";
import SelectSlotModal from "@/components/modals/SelectSlotModal.vue";

export default {
  name: "SlotCard",
  components: {
    Modal,
    SelectSlotModal,
  },
  props: {
    slotProp: {
      type: Object,
      required: true,
    },
    extendable: {
      type: Boolean,
      required: false,
      default: false,
    },
    extended: {
      type: Boolean,
      required: false,
      default: false,
    },
    intervention: {
      type: Object,
      required: false,
      default: null,
    },
    zipcode: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
  },
  emits: ["extend", "collapse"],
  data() {
    return {
      durationTripFromPreviousPlanningEvent: null,
      durationTripToNextPlanningEvent: null,
    };
  },
  computed: {
    previousPlanningEventStartAndEndDate() {
      if (this.slotProp.previous_planning_event == null) {
        return null;
      }

      const previousPlanningEventStartDate = this.$filters.dateStringToHumanizedDate(this.slotProp.previous_planning_event.start_date);
      const previousPlanningEventStartTime = this.slotProp.previous_planning_event.start_time;
      const previousPlanningEventEndDate = this.$filters.dateStringToHumanizedDate(this.slotProp.previous_planning_event.end_date);
      const previousPlanningEventEndTime = this.slotProp.previous_planning_event.end_time;

      if (previousPlanningEventStartDate == previousPlanningEventEndDate) {
        return `${previousPlanningEventStartDate} de ${previousPlanningEventStartTime.substring(0, 5)} à ${previousPlanningEventEndTime.substring(0, 5)}`;
      } else {
        return `Du ${previousPlanningEventStartDate} à ${previousPlanningEventStartTime.substring(0, 5)} au ${previousPlanningEventEndDate} à ${previousPlanningEventEndTime.substring(0, 5)}`;
      }
    },
    nextPlanningEventStartAndEndDate() {
      if (this.slotProp.next_planning_event == null) {
        return null;
      }

      const nextPlanningEventStartDate = this.$filters.dateStringToHumanizedDate(this.slotProp.next_planning_event.start_date);
      const nextPlanningEventStartTime = this.slotProp.next_planning_event.start_time;
      const nextPlanningEventEndDate = this.$filters.dateStringToHumanizedDate(this.slotProp.next_planning_event.end_date);
      const nextPlanningEventEndTime = this.slotProp.next_planning_event.end_time;

      if (nextPlanningEventStartDate == nextPlanningEventEndDate) {
        return `${nextPlanningEventStartDate} de ${nextPlanningEventStartTime.substring(0, 5)} à ${nextPlanningEventEndTime.substring(0, 5)}`;
      } else {
        return `Du ${nextPlanningEventStartDate} à ${nextPlanningEventStartTime.substring(0, 5)} au ${nextPlanningEventEndDate} à ${nextPlanningEventEndTime.substring(0, 5)}`;
      }
    },
    interventionDuration() {
      if (this.intervention == null) {
        return "Inconnu";
      } else {
        return `${this.intervention.duration} minutes`;
      }
    },
    interventionAddress() {
      if (this.intervention == null) {
        return `${this.zipcode} ${this.countryCode}`;
      } else {
        const location = this.intervention.location;

        return `${location.street}, ${location.zipcode} ${location.city} ${location.country_code}`;
      }
    },
  },
  methods: {
    collapse() {
      this.$emit("collapse");
    },
    extend() {
      this.$emit("extend");
    },
    toggle() {
      if (this.extended) {
        this.collapse();
      } else if (this.extendable) {
        this.extend();
      }
    },
    
    openInterventionInNewTab(interventionId) {
      const routeData = this.$router.resolve({name: "intervention", params: {id: interventionId}});
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>

  .slot {

    .slot-previous-event {
      background-color: #f5f5f5;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border: 1px solid #ccc;
      border-bottom: none;

      overflow: hidden;
      transition: height 0.3s ease-in-out;
      height: 0px;

      &.visible {
        height: 90px;
      }
    }

    .slot-body {
      padding: 10px;
      border: 1px solid #ccc;
      background-color: white;
      display: flex;
    }

    .slot-next-event {
      background-color: #f5f5f5;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border: 1px solid #ccc;
      border-top: none;

      overflow: hidden;
      transition: height 0.3s ease-in-out;
      height: 0px;

      &.visible {
        height: 90px;
      }
    }

  }

  .extend-icon {
    transition: transform 0.3s ease-in-out;

    &.upside-down {
      transform: rotate(180deg);
    }
  }

</style>
