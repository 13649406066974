<template>
  <Modal ref="modal" width="medium">
    <LoaderView v-if="interventions === null" />

    <div v-else>
      <div
        v-for="intervention in interventions"
        :key="intervention.id"
        class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          :disabled="intervention.rdv_date == null"
          :checked="selectedInterventionIds.includes(intervention.id)"
          @change="onSelect(intervention, $event.target.checked)">
        <label class="form-check-label">
          Intervention<span v-if="intervention.rdv_date != null"> du {{ $filters.dateStringToHumanizedDate(intervention.rdv_date) }}</span> à {{ intervention.location.city }}<span v-if="intervention.rdv_date == null"> (pas de date de rdv)</span>
        </label>
      </div>

      <div v-if="interventions.length === 0">
        <i>Aucune intervention trouvée</i>
      </div>  
    </div>

    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-primary"
          :class="{ 'loading': exporting }"
          :disabled="selectedInterventionIds.length === 0 || exporting"
          @click="submit">
          Exporter
        </button>

        <button
          class="btn-secondary"
          @click="hide">
          Annuler
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "InterventionsRecapExportPdfModal",
  components: {
    Modal,
    LoaderView,
  },
  data() {
    return {
      customer: null,
      interventions: null,
      selectedInterventionIds: [],
      exporting: false,
    };
  },
  computed: {
    options() {
      return this.interventions.map((intervention) => ({
        label: `Intervention du ${intervention.rdv_date} à ${intervention.location.city}`,
        value: intervention.id,
        disabled: intervention.rdv_date == null,
      }));
    },
  },
  methods: {
    hide() {
      this.$refs.modal.hide();
    },
    show(customer, selectedInterventionIds = []) {
      this.customer = customer;
      this.selectedInterventionIds = selectedInterventionIds;
      this.load();
      this.$refs.modal.show();
    },
    load() {
      this.interventions = null;

      this.$services.intervention.list({
        customer_id: this.customer.id,
        limit: 100,
      }).then((response) => {
        this.interventions = response.data.results;
      });
    },
    onSelect(intervention, checked) {
      if (checked) {
        if (!this.selectedInterventionIds.includes(intervention.id)) {
          this.selectedInterventionIds = [...this.selectedInterventionIds, intervention.id];
        }
      } else {
        this.selectedInterventionIds = this.selectedInterventionIds.filter((id) => id !== intervention.id);
      }
    },
    submit() {
      this.exporting = true;

      this.$services.customer.interventionsRecapPdf(this.customer.id, this.selectedInterventionIds)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          var filename = "mon_récapitulatif.pdf";

          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.hide();
        }).finally(() => {
          this.exporting = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>