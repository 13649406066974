<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;"><i class="bi bi-person-vcard" /> Contacts</span>
    </div>
    
    <div class="card-body">
      <div class="vertical-spaced-container">
        <div
          v-for="(person, index) in modelValue.contacts"
          :key="person.id">
          <div style="display: flex;">
            <strong style="margin-right: 5px;">
              {{ $filters.fullname(person) }}
            </strong>

            <button class="small-tool-btn" @click="$refs.personModal.edit(person)">
              <i class="bi bi-pencil-square" />
            </button>

            <i
              v-if="index == modelValue.main_contact_rank"
              style="margin-left: 5px;"
              class="bi bi-star-fill tooltip">
              <span class="tooltip-text">
                Contact principal
              </span>
            </i>

            <i
              v-else
              class="bi bi-star tooltip"
              style="margin-left: 5px; cursor: pointer;"
              @click="setMainContactRank(index)">
              <span class="tooltip-text">
                Sélectionner comme contact principal
              </span>
            </i>

            <i
              v-if="index != modelValue.main_contact_rank"
              class="bi bi-trash tooltip"
              style="margin-left: 5px; cursor: pointer;"
              @click="destroy(person)">
              <span class="tooltip-text">
                Supprimer
              </span>
            </i>
          </div>

          <p>
            <i v-if="person.notes">
              {{ person.notes }}
            </i>
            <i>
              Aucune note
            </i>
          </p>

          <p>
            <span v-if="person.email">{{ person.email }}</span><i v-else>Pas d'email</i>
          </p>
          <p>
            <span v-if="person.phone_number">{{ person.phone_number }}</span><i v-else>Pas de numéro de téléphone</i>
          </p>
          <p>
            Parle {{ person.language }}
          </p>
        </div>

        <div>
          <button
            class="btn-link"
            @click="$refs.personModal.new()">
            <i class="bi bi-plus-circle" /> Ajouter un contact
          </button>
        </div>
      </div>

      <PersonModal
        ref="personModal"
        @onPersonCreated="onPersonCreated"
        @onPersonChanged="onPersonChanged" />
    </div>
  </div>
</template>

<script>

import PersonModal from "@/components/modals/PersonModal.vue";

export default {
  name: "CustomerPersonsCard",
  components: {
    PersonModal,
  },
  props: {
    modelValue: { // The customer
      type: Object,
      required: true,
      validator: (value) => {
        for (const field of [
          "contacts",
          "main_contact_rank",
        ]) {
          if (!Object.prototype.hasOwnProperty.call(value, field)) {
            console.warn(`Missing field '${field}' in prop modelValue of component CustomerPersonsCard`);
            return false;
          }
        }
      },
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onPersonCreated(newPerson) {
      this.$emit(
        "update:modelValue", 
        {
          ...this.modelValue,
          contacts: [
            ...this.modelValue.contacts,
            newPerson,
          ],
        },
      );
    },
    onPersonChanged(newPerson) {
      this.$emit(
        "update:modelValue",
        {
          ...this.modelValue,
          contacts: this.modelValue.contacts.map((person) => {
            if (person.id === newPerson.id) {
              return newPerson;
            }
            return person;
          }),
        },
      );
    },
    setMainContactRank(index) {
      this.$services.customer.patch(
        this.modelValue.id,
        {
          main_contact_rank: index,
        },
      ).then((response) => {
        this.$emit("update:modelValue", 
          {
            ...this.modelValue,
            main_contact_rank: response.data.main_contact_rank,
          },
        );
      });
    },
    destroy(person) {
      this.$services.person.delete(
        person.id,
      ).then(() => {
        this.$emit(
          "update:modelValue",
          {
            ...this.modelValue,
            contacts: this.modelValue.contacts.filter((p) => p.id !== person.id),
          },
        );
      });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>