<template>
  <LocalList
    :rowComponent="rowComponent"
    :load="loadBatches"
    noResultMessage="Aucun lot" />
</template>

<script>

import LocalList from "@/components/listV2/LocalList.vue";
import BatchRow from "@/components/rows/batch/BatchRow.vue";
import { shallowRef } from "vue";

export default {
  name: "ProductObjectStock",
  components: {
    LocalList,
  },
  data() {
    return {
      rowComponent: shallowRef(BatchRow),
    };
  },
  computed: {
    productId() {
      return this.$route.params.id;
    },
  },
  methods: {
    loadBatches(limit, page) {
      return this.$services.batch.list({ product_id: this.productId }, limit, page);
    },
    cancel() {
      this.$router.push({ name: "product-list" });
    },
  },
};

</script>

<style lang="scss" scoped>

</style>