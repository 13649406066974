<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div class="card" style="max-width: 400px;">
      <div class="card-header" style="justify-content: space-between;">
        <div class="text-muted">
          <RegisterIcon style="width: 26px; height: 26px; margin-right: 5px;" />
          <span>Total dans la caisse</span>
        </div>
        <div class="flow-spaced-container">
          <SimpleButton
            tooltipText="Sortir de l'argent de la caisse"
            icon="bi bi-bank"
            @click="$refs.removeCashFromRegisterModal.show()" />
          <ExportButton
            uri="/register-adjustments/export/" />
        </div>
      </div>
      <div class="card-body">
        <span v-if="totalInMainRegister != null">{{ totalInMainRegister / 100 }}€</span>
        <Loader v-else />
      </div>
    </div>

    <div class="flow-spaced-container">
      <div class="card" style="margin-bottom: 10px;">
        <div class="card-header" style="justify-content: space-between;">
          <div class="text-muted">
            <i class="bi bi-clock-history" style="font-size: 16px;" />
            Paiements en liquide transférés dans la caisse
          </div>
        </div>
        <div class="card-body">
          <LocalList
            ref="cashPaymentsList"
            :rowComponent="cashPaymentRowComponent"
            :load="loadCashPayments"
            :columnsNames="['Montant', 'Facture', 'Technicien', 'Statut', 'Odoo']"
            noResultMessage="Aucun paiment" />
        </div>
      </div>

      <div class="card" style="margin-bottom: 10px;">
        <div class="card-header" style="justify-content: space-between;">
          <div class="text-muted">
            <i class="bi bi-clock-history" style="font-size: 16px;" />
            Ajustements de caisse
          </div>
        </div>
        <div class="card-body">
          <LocalList
            v-if="showRegisterAdjustmentsList"
            :rowComponent="registerAdjustmentRowComponent"
            :load="loadRegisterAdjustments"
            :columnsNames="['Montant', 'Odoo']"
            noResultMessage="Aucun ajustement" />
        </div>
      </div>
    </div>

    <InputModal
      ref="removeCashFromRegisterModal"
      label="Combien voulez-vous retirer de la caisse ?"
      type="number"
      @click.stop
      @submit="removeCashFromRegister" />
  </div>
</template>

<script>

import LocalList from "@/components/listV2/LocalList.vue";
import CashPaymentRow from "@/components/rows/cashTransaction/CashPaymentRow.vue";
import RegisterAdjustmentRow from "@/components/rows/registerAdjustment/RegisterAdjustmentRow.vue";
import { shallowRef } from "vue";
import Loader from "@/components/Loader.vue";
import InputModal from "@/components/modals/InputModal.vue";
import RegisterIcon from "@/assets/iconsVue/RegisterIcon.vue";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";
import ExportButton from "@/components/vueComponents/button/ExportButton.vue";

export default {
  name: "RegisterView",
  components: {
    LocalList,
    Loader,
    InputModal,
    RegisterIcon,
    SimpleButton,
    ExportButton,
  },
  data() {
    return {
      showRegisterAdjustmentsList: true,
      cashPaymentRowComponent: shallowRef(CashPaymentRow),
      registerAdjustmentRowComponent: shallowRef(RegisterAdjustmentRow),
      totalInMainRegister: null,
      exportingRegisterCashTransactionsToCsv: false,
    };
  },
  mounted() {
    this.loadTotalIntoRegister();
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    loadCashPayments(page) {
      return this.$services.payment.listCashPaymentsInRegister();
    },
    loadRegisterAdjustments(page) {
      return this.$services.registerAdjustment.list({}, 100, page);
    },
    loadTotalIntoRegister() {
      this.$services.registerAdjustment.totalIntoRegister().then((total) => {
        this.totalInMainRegister = total;
      });
    },
    removeCashFromRegister(amount) {
      this.$refs.removeCashFromRegisterModal.hide();
      this.$services.registerAdjustment.create({ amount: -amount * 100 })
        .then(() => {
          this.$services.registerAdjustment.totalIntoRegister().then((total) => {
            this.totalInMainRegister = total;
          });
          this.refreshRegisterAdjustmentsList();
        });
    },
    refreshRegisterAdjustmentsList() {
      this.showRegisterAdjustmentsList = false;

      setTimeout(() => {
        this.showRegisterAdjustmentsList = true;
      }, 100);
    },
  },
};

</script>


<style lang="scss" scoped>

</style>
  