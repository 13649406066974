<template>
  <Modal ref="confirm-modal" width="small">
    <template #default>
      <strong>{{ message }}</strong>
    </template>
    <template #buttons>
      <button
        :disabled="loading"
        class="btn-primary"
        style="margin-right: 10px;"
        @click="$emit('confirm', data);">
        <i v-if="loading" class="fa fa-spinner fa-spin" />
        {{ confirmText }}
      </button>
      <button
        :disabled="loading"
        class="btn-secondary"
        @click="hide(); $emit('cancel')">
        {{ cancelText }}
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "ConfirmModal",
  components: {
    Modal,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["confirm", "cancel"],
  data() {
    return {
      message: "",
      confirmText: "Confirmer",
      cancelText: "Annuler",
      data: null,
    };
  },
  methods: {
    show(message, confirmText = "Confirmer", cancelText = "Annuler", data = null) {
      this.message = message;
      this.confirmText = confirmText;
      this.cancelText = cancelText;
      this.data = data;
      
      this.$refs["confirm-modal"].show();
    },
    hide() {
      this.$refs["confirm-modal"].hide();
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>