<template>
  <div>
    <p v-if="errorFetchingSlots">
      Aïe ! Il y a eu une erreur lors du chargement des créneaux disponibles... Essayez de recharger la page ou signalez le problème au support technique.
    </p>

    <p v-else-if="invalidAddress">
      Nous n'avons pas pu localiser cette adresse
    </p>

    <LoaderView v-else-if="slots == null" :text="`Chargement des créneaux disponibles pour ${title}`" />
      
    <i v-else-if="slots.length == 0">
      Aucun créneau disponible pour {{ title }}
    </i>

    <div v-else class="vertical-spaced-container">
      <SlotCard 
        v-for="(slot, index) in slots"
        :key="index"
        :extendable="extendable"
        :extended="slot.extended == true"
        :slotProp="slot"
        :intervention="intervention"
        :zipcode="zipcode"
        :countryCode="countryCode"
        @extend="extendSlot(slot)"
        @collapse="slot.extended = false" />
    </div>
  </div>
</template>

<script>

import SlotCard from "@/views/interventions/SlotCard.vue";
import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "FreeSlots",
  components: {
    SlotCard,
    LoaderView,
  },
  props: {
    intervention: {
      type: Object,
      required: false,
      default: null,
    },
    technicianIds: {
      type: Array,
      required: false,
      default: null,
    },
    activityIds: {
      type: Array,
      required: false,
      default: null,
    },
    zipcode: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
    dateFrom: {
      type: String,
      required: false,
      default: () => null,
    },
    dateTo: {
      type: String,
      required: false,
      default: () => null,
    },
    extendable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["extend"],
  data() {
    return {
      errorFetchingSlots: false,
      invalidAddress: false,
      slots: null,
    };
  },
  computed: {
    title() {
      if (this.intervention) {
        return `l'intervention ${this.intervention.ref}`;
      } else {
        return `une intervention pour ${this.activityIds} au code postal ${this.zipcode} en ${this.countryCode}`;
      }
    },
  },
  watch: {
    countryCode(newVal) {
      if (newVal != null && newVal.length > 0 && this.zipcode != null && this.zipcode.length > 0) {
        this.fetchSlots();
      }
      
    },
    zipcode(newVal) {
      if (newVal != null && newVal.length > 0 && this.countryCode != null && this.countryCode.length > 0) {
        this.fetchSlots();
      }
    },
    activityIds(newVal, oldVal) {
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i] !== oldVal[i]) {
          this.fetchSlots();
          break;
        }
      }
    },
  },
  mounted() {
    if ((this.zipcode && this.countryCode) || this.intervention) {
      this.fetchSlots();
    }
  },
  methods: {
    fetchSlots() {
      this.slots = null;
      this.errorFetchingSlots = false;
      this.invalidAddress = false;

      if (this.intervention) {
        this.$services.planning.fetchFreeSlotsForIntervention(
          this.intervention.id,
          this.technicianIds != null && this.technicianIds.length > 0 ? this.technicianIds : null,
          this.dateFrom,
          this.dateTo,
        )
          .then((response) => {
            this.slots = response.data;
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              this.invalidAddress = true;
              return;
            } else {
              this.errorFetchingSlots = true;
              throw err;
            }
          });

      } else {
        this.$services.planning.fetchFreeSlotsForZipcode(
          this.zipcode,
          this.countryCode,
          this.technicianIds != null && this.technicianIds.length > 0 ? this.technicianIds : null,
          this.activityIds != null && this.activityIds.length > 0 ? this.activityIds : null,
          this.dateFrom,
          this.dateTo,
        )
          .then((response) => {          
            this.slots = response.data;
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              this.invalidAddress = true;
              return;
            } else {
              this.errorFetchingSlots = true;
              throw err;
            }
          });
      }
    },
    extendSlot(slot) {
      slot.extended = true;
      this.$emit("extend", slot);
    },
  },
  
};
</script>

<style lang="scss" scoped>


</style>
