<template>
  <div class="origin-selector flow-spaced-container">
    <div
      v-for="(origin, index) in origins"
      :key="index"
      class="origin-button-container">
      <div
        class="origin-button"
        :class="{ 'selected': internalValue != null && internalValue.code == origin.code }"
        @click="internalValue = origin">
        <img v-if="origin.icon" :src="getImageUrl(origin.icon)" :alt="origin.code">
        <span>{{ origin.code }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import { useOriginsStore }  from "@/stores/origins";

export default {
  name: "OriginInput",
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      originsStore: useOriginsStore(),
      internalValue: this.modelValue,
    };
  },
  computed: {
    origins() {
      return this.originsStore.getOrigins;
    },
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit(
        "update:modelValue",
        newVal,
      );
      this.$emit(
        "change",
        newVal,
      );
    },
  },
  methods: {
    getImageUrl(image) {
      return `${process.env.VUE_APP_BACKEND_ROOT_URL}${image}`;
    },
  },
};
</script>

<style lang="scss" scoped>

.origin-selector {
  cursor: pointer;

  .origin-button-container {

    .origin-button {
      border: 1px solid rgb(100, 100, 100);;
      border-radius: 10px;
      padding: 5px;
      color: rgb(100, 100, 100);;
      width: 100px;
      height: 100%;
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        border: 1px solid black;
        color: black;
      }

      &.selected {
        border: none;
        background: $primary;
        color: black;
      }
    }
  }
}

</style>