<template>
  <div v-if="!meIsTechnician && !meIsAdmin" />
  <div v-else style="padding: 10px;">
    <div class="vertical-spaced-container">
      <div>
        <div class="flow-spaced-container" style="align-items: flex-end;">
          <div class="filter-item">
            <div class="filter-item-title">
              Filter par période
            </div>
            <DateRangeInput v-model="period" />
          </div>

          <div v-if="meIsAdmin" class="filter-item">
            <div class="filter-item-title">
              Technicien
            </div>

            <TechnicianSelect
              v-model="technicianId"
              :meOption="meIsTechnician" />
          </div>

          <div v-if="stats">
            Les statistiques de {{ stats.technician }} entre {{ $filters.dateStringToHumanizedDate(stats.date_from) }} et {{ $filters.dateStringToHumanizedDate(stats.date_to) }}
          </div>
        </div>
      </div>

      <div v-if="meIsTechnician || technicianId != null">
        <div class="flow-spaced-container">
          <div class="card small-card red clickable" @click="$router.push({ name: 'intervention-list.todo' })">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-briefcase" />
                <div>Mes interventions à traiter</div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <span v-else class="text">{{ stats.nb_interventions_todo }}</span>
            </div>
          </div>

          <div class="card small-card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-cash-coin" />
                <div>Mon chiffre d'affaire</div>
                <div class="tooltip">
                  <i class="bi bi-question-circle" />
                  <div class="tooltip-text wide right">
                    Le chiffre d'affaire sans les taxes et après remboursement qui m'est attribué (partagé entre tous les techniciens si plusieurs techniciens sont intervenus)
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span class="text" style="margin-right: 5px;">{{ $filters.numberWithSpaces(stats.total_turnover_excl_tax_net) }}€</span>
                  <span class="text-muted">net HT</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card small-card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-cash-coin" />
                <div>Mon chiffre d'affaire journalier</div>
                <div class="tooltip">
                  <i class="bi bi-question-circle" />
                  <div class="tooltip-text wide right">
                    Le chiffre d'affaire sans les taxes et après remboursement qui m'est attribué (partagé entre tous les techniciens si plusieurs techniciens sont intervenus)
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span
                    v-if="stats.nb_of_working_half_days > 0"
                    class="text"
                    style="margin-right: 5px;">
                    ~{{ $filters.numberWithSpaces(round(stats.total_turnover_excl_tax_net * 2 / stats.nb_of_working_half_days)) }}€
                  </span>
                  <span
                    v-else
                    class="text"
                    style="margin-right: 5px;">
                    ~0€
                  </span>
                  <span class="text-muted">net HT par jour</span>
                </div>
              </div>
            </div>
          </div>
          
          <div class="card small-card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-calendar-week" />
                <div>Nombre de jours travaillés</div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span class="text">{{ round(stats.nb_of_working_half_days / 2) }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card small-card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-briefcase" />
                <div>Mes interventions réalisées</div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span class="text" style="margin-right: 5px;">{{ stats.nb_interventions_done }}</span>
                  <span class="text-muted">int. réalisées</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card small-card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-briefcase" />
                <div>Ma moyenne d'interventions par jour travaillé</div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span
                    v-if="stats.nb_of_working_half_days > 0"
                    style="margin-right: 5px;"
                    class="text">~{{ round(stats.nb_interventions_done * 2 / stats.nb_of_working_half_days, 1) }}</span>
                  <span v-else class="text" style="margin-right: 5px;">~0</span>
                  <span class="text-muted">int. par jour</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card small-card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-briefcase" />
                <div>Mon chiffre d'affaire par intervention</div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span
                    v-if="stats.nb_interventions_done > 0"
                    style="margin-right: 5px;"
                    class="text">~{{ $filters.numberWithSpaces(round(stats.total_turnover_excl_tax_net / stats.nb_interventions_done)) }}€</span>
                  <span v-else class="text" style="margin-right: 5px;">~0€</span>
                  <span class="text-muted">net HT par int.</span>
                </div>
              </div>
            </div>
          </div>

          

          <div class="card small-card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-journal-bookmark" />
                <div>Mes contrats gérés</div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <span v-else class="text">{{ stats.nb_assigned_contract_iterations }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="meIsTechnician || technicianId != null" class="card">
        <div class="card-header">
          <div class="flow-spaced-container align-middle text-muted">
            <i class="bi bi-cash-coin" />
            <div>Mes clients en retard de paiement</div>
          </div>
        </div>
        <div class="card-body">
          <LoaderView v-if="loading" />
          <div v-else-if="stats.unpaid_invoices.length > 0">
            <div style="margin-bottom: 10px;">
              <span class="text" style="margin-right: 5px;">{{ stats.unpaid_invoices.length }}</span>
              <span class="text-muted" style="margin-right: 5px;">factures,</span>
              <span class="text" style="margin-right: 5px;">{{ $filters.numberWithSpaces(stats.total_unpaid_turnover_excl_tax_net) }}€</span>
              <span class="text-muted" style="margin-right: 5px;">net HT en retard</span>
              <span class="tooltip">
                <i class="bi bi-question-circle text-muted" />
                <span class="tooltip-text wide left">
                  Le montant total sans les taxes et après remboursement des factures qui concernent des interventions sur lesquelles j'ai travaillé, et dont le troisième rappel de paiement à déjà été envoyé mais qui n'a toujours pas été complètement payée.
                </span>
              </span>
            </div>
            <table>
              <tr>
                <th>Référence</th>
                <th>Date</th>
                <th>Reste à payer</th>
              </tr>
              <tr
                v-for="unpaidInvoice in stats.unpaid_invoices"
                :key="unpaidInvoice.id">
                <td>
                  {{ unpaidInvoice.ref }}
                </td>
                <td>
                  {{ $filters.dateStringToHumanizedDate(unpaidInvoice.created_at) }}
                </td>
                <td>
                  {{ unpaidInvoice.to_pay_in_euro }}€
                </td>
              </tr>
            </table>
          </div>
          <div v-else>
            Fécilitation ! Tout a été payé en temps et en heure
          </div>
        </div>
      </div>

      <div v-else>
        <i>Commencez par sélectionner un technicien</i>
      </div>
    </div>
  </div>
</template>

<script>

import { api } from "@/api";
import LoaderView from "@/components/LoaderView.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import { useMeStore } from "@/stores/me";
import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";

export default {
  name: "DashboardView",
  components: {
    LoaderView,
    DateRangeInput,
    TechnicianSelect,
  },
  data() {
    const requestISODateFrom = this.$route.query.date_from;
    const requestISODateTo = this.$route.query.date_to;

    const dateFrom = requestISODateFrom ? requestISODateFrom : `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`;
    const dateTo = requestISODateTo ? requestISODateTo : new Date().toISOString().split("T")[0];

    const meStore = useMeStore();


    return {
      loadingStats: false,
      meStore: meStore,
      stats: null,
      period: [dateFrom, dateTo],
      technicianId: meStore.meIsTechnician ? meStore.getMe.id : null,
    };
  },
  computed: {
    me() {
      return this.meStore.getMe;
    },
    meIsAdmin() {
      return this.meStore.meIsAdmin;
    },
    meIsTechnician() {
      return this.meStore.meIsTechnician;
    },
    uriFilters() {
      var args = [];

      if (this.period[0]) {
        args.push(`date_from=${this.period[0]}`);
      }

      if (this.period[1]) {
        args.push(`date_to=${this.period[1]}`);
      }

      if (this.meIsAdmin && this.technicianId != null) {
        args.push(`technician=${this.technicianId}`);
      }

      return args.join("&");
    },
    loading() {
      return this.loadingStats || this.stats == null;
    },
  },
  watch: {
    period() {
      this.$router.push(
        {
          query: {
            date_from: this.period[0],
            date_to: this.period[1],
          },
        },
      );
      this.fetchData();
    },
    technicianId() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!this.meIsTechnician && this.technicianId == null) {
        return;

      }
      this.loadingStats = true;
      return api.get(`/stats/my-technician-stats/?${this.uriFilters}`)
        .then((response) => {
          this.stats = response.data;
        })
        .finally(() => {
          this.loadingStats = false;
        });
    },
    round(value, nbDigits = 0) {
      return Math.round(value * Math.pow(10, nbDigits)) / Math.pow(10, nbDigits);
    },
  },
};
</script>

<style lang="scss" scoped>

  .small-card {
    height: 220px;
    width: 250px;

    &.red {
      background-color: #f8d7da;
    }

    .card-header {
      align-items: left;
      text-align: left;
    }

    .card-body {
      text-align: center;
      align-items: middle;
      justify-content: center;
    }

    .text {
      font-size: 34px;
      font-weight: 500;
    }
  }

  table {
    th {
      text-align: left;
    }
  }

</style>