<template>
  <div>
    <div class="horizontal-spaced-container">
      <router-link
        class="btn-link"
        :to="{ name: 'customer', params: { 'id': modelValue.id } }"
        @click.stop>
        {{ $filters.printCustomer(modelValue) }}
      </router-link>

      <router-link
        class="btn-link"
        :to="{ name: 'customer', params: { 'id': modelValue.id } }"
        target="_blank"
        @click.stop>
        <i class="bi bi-box-arrow-up-right" />
      </router-link>

      <button
        class="small-tool-btn"
        @click="showDetails">
        <i class="bi bi-sticky" />
      </button>

      <div v-if="modelValue.warnings.length > 0" class="tooltip">
        <i class="bi bi-exclamation-triangle" />

        <div class="tooltip-container">
          <div
            v-for="(warning, index) in modelValue.warnings"
            :key="index">
            {{ warning }}
          </div>
        </div>
      </div>

      <InputModal
        ref="remarksModal"
        v-model="editedRemarks"
        :loading="submittingRemarks"
        type="text"
        label="Remarques"
        @submit="submitRemarks" />
    </div>

    <small v-if="showBillingInfo">
      Facturation au {{ modelValue.billing_street_number }} {{ modelValue.billing_street }} {{ modelValue.billing_bp }}, {{ modelValue.billing_zipcode }} {{ modelValue.billing_city }} {{ modelValue.billing_country_code }}
    </small>
  </div>
</template>

<script>

import InputModal from "@/components/modals/InputModal.vue";

export default {
  name: "CustomerWidget",
  components: {
    InputModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    showBillingInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editedRemarks: null,
      submittingRemarks: false,
    };
  },
  methods: {
    showDetails() {
      this.editedRemarks = this.modelValue.remarks;
      this.$refs.remarksModal.show();
    },
    submitRemarks(remarks) {
      this.submittingRemarks = true;

      this.$services.customer.patch(
        this.modelValue.id,
        { remarks: remarks },
      ).then((response) => {
        this.$emit(
          "update:modelValue", 
          {
            ...this.modelValue,
            remarks: response.data.remarks,
          },
        );
        
        this.editedRemarks = null;
        this.$refs.remarksModal.hide();

      }).finally(() => {
        this.submittingRemarks = false;
      });
      
    },
  },
};
</script>

<style scoped>


</style>
