<template>
  <button
    v-if="canSendToRegister"
    class="tool-btn tooltip"
    :class="{ 'loading': sendingToRegister }"
    :disabled="sendingToRegister"
    @click.stop="sendToRegister">
    <i class="bi bi-r-square" />
    <span class="tooltip-text left">
      Transférer vers la caisse
    </span>
  </button>
</template>

<script>

import { useMeStore } from "@/stores/me";

export default {
  name: "CashPaymentToolButton",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      sendingToRegister: false,
      meStore: useMeStore(),
    };
  },
  computed: {
    canSendToRegister() {
      return this.meIsAdmin && this.modelValue.transfered_to_register_at == null && this.modelValue.transfered_by_wire_at == null && this.modelValue.invoice.odoo_payment_state != "paid";
    },
    meIsAdmin() {
      return this.meStore.meIsAdmin;
    },
  },
  methods: {
    sendToRegister() {
      const answer = confirm("Êtes-vous sûr de vouloir transférer l'argent de ce paiement dans la caisse ?");
      if (!answer) {
        return;
      }

      this.sendingToRegister = true;

      this.$services.payment.sendToRegister(this.modelValue.type, this.modelValue.id)
        .then((response) => {
          this.$emit("update:modelValue", response.data);
        })
        .finally(() => {
          this.sendingToRegister = false;
        });
    },
  },
};

</script>

<style>

</style>