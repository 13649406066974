<template>
  <ObjectViewLayout
    :title="title"
    :loading="customer1 == null || customer2 == null || transferts == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'customer-list' })">
    <template #title-icon-left>
      <i class="bi bi-person" />
    </template>

    <div class="vertical-spaced-container">
      <div>
        Tous les éléments suivants vont être transférés du client {{ customer1.id }} {{ $filters.printCustomer(customer1) }} au client {{ customer2.id }} {{ $filters.printCustomer(customer2) }}
      </div>

      <div
        v-for="(transfertObj, index) in transferts"
        :key="index">
        {{ transfertObj }}
      </div>

      <div>
        Puis le client {{ customer1.id }} {{ $filters.printCustomer(customer1) }} sera supprimé
      </div>

      <div>
        Etes vous sûr de vouloir procéder ?
      </div>

      <div>
        <button
          class="btn-primary"
          :class="{ 'loading': proceeding }"
          :disabled="proceeding"
          @click="proceed">
          Oui
        </button>
      </div>
    </div>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";

export default {
  name: "CustomersFusionView",
  components: {
    ObjectViewLayout,
  },
  data() {
    return {
      customer1: null,
      customer2: null,
      transferts: null,
      errorFetching: false,
      proceeding: false,
    };
  },
  computed: {
    title() {
      if (this.customer1 == null || this.customer2 == null) {
        return "Chargement des infos clients...";
      } else {
        return `Fusion des clients ${this.$filters.printCustomer(this.customer1)} et ${this.$filters.printCustomer(this.customer2)}`;
      }
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      Promise.all([
        this.fetchCustomer1(),
        this.fetchCustomer2(),
        this.fetchTransferts(),
      ]);
    },
    fetchCustomer1() {
      return this.$services.customer.retrieve(this.$route.params.id)
        .then((response) => {
          this.customer1 = response.data;
        })
        .catch((err) => {
          this.errorFetching = true;
          throw err;
        });
    },
    fetchCustomer2() {
      return this.$services.customer.retrieve(this.$route.params.id2)
        .then((response) => {
          this.customer2 = response.data;
        })
        .catch((err) => {
          this.errorFetching = true;
          throw err;
        });
    },
    fetchTransferts() {
      return this.$services.customer.prepareAbsorbe(this.$route.params.id2, this.$route.params.id)
        .then((response) => {
          this.transferts = response.data;
        })
        .catch((err) => {
          this.errorFetching = true;
          throw err;
        });
    },
    cancel() {
      this.$router.push({ name: "customer-list" });
    },
    proceed() {
      this.proceeding = true;
      return this.$services.customer.absorbe(this.$route.params.id2, this.$route.params.id)
        .then(() => {
          this.cancel();
        })
        .finally(() => {
          this.proceeding = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>



</style>